import { checkUnreachable } from '../lib/tsUtils';
import {
  AlertActionType,
  alertActionTypes,
  LocaleActionType,
  localeActionTypes,
  ReferralActionType,
  referralActionTypes,
} from './actionTypes';
import { ReducerType } from './types';

const localeReducer: ReducerType = (state, action) => {
  const type = action.type as LocaleActionType;

  switch (type) {
    case localeActionTypes.SET_LOCALE: {
      return {
        ...state,
        localeCode: action.payload,
      };
    }
    case localeActionTypes.SET_IS_RTL: {
      return {
        ...state,
        rtl: action.payload ?? false,
      };
    }

    default:
      checkUnreachable(type);
      return state;
  }
};

const alertReducer: ReducerType = (state, action) => {
  const type = action.type as AlertActionType;

  switch (type) {
    case alertActionTypes.SET_ALERT: {
      const { error = true, open = true, message } = action.payload;

      return {
        ...state,
        alert: {
          error,
          open,
          message,
        },
      };
    }

    case alertActionTypes.HIDE_ALERT: {
      return {
        ...state,
        alert: {
          error: false,
          open: false,
          message: '',
        },
      };
    }

    default:
      checkUnreachable(type);
      return state;
  }
};

const referralReducer: ReducerType = (state, action) => {
  const type = action.type as ReferralActionType;

  switch (type) {
    case referralActionTypes.SET_REVIEW_TAB:
      return {
        ...state,
        reviewTab: action.payload,
      };

    case referralActionTypes.SET_FILE_NOTES:
      return {
        ...state,
        patientNotes: action.payload,
      };

    case referralActionTypes.SET_PATIENT_ALLERGIES:
      return {
        ...state,
        patientAllergies: action.payload,
      };

    case referralActionTypes.SET_PREVIOUS_SURGERIES:
      return {
        ...state,
        patientSurgeries: action.payload,
      };

    case referralActionTypes.SET_QUESTIONNAIRE_DATA:
      return {
        ...state,
        currentState: action.payload.currentState,
        testStatus: action.payload.testStatus,
        drReviewStatus: action.payload.drReviewStatus,
        patientQuestionStatus: action.payload.patientQuestionStatus,
        attachedFiles: action.payload.attachedFiles,
        hasAttachedFiles: action.payload.hasAttachedFiles,
        referralData: action.payload.referralData,
        reviewData: action.payload.reviewData,
      };

    case referralActionTypes.SET_REFERRAL_DATA:
      return {
        ...state,
        referralData: action.payload.referralData,
      };

    case referralActionTypes.SET_TEST_DATA:
      return {
        ...state,
        testData: action.payload,
      };

    case referralActionTypes.SET_MEDICATION_DATA:
      return {
        ...state,
        medsData: action.payload,
      };

    case referralActionTypes.SET_HQ_DATA:
      return {
        ...state,
        completedHqs: action.payload,
      };

    case referralActionTypes.SET_INCOMPLETE_HQS:
      return {
        ...state,
        incompleteHQs: action.payload,
      };

    case referralActionTypes.SET_REFERRAL_LIST_FILTERS:
      return {
        ...state,
        workflowFilterOptions: { ...state.workflowFilterOptions, ...action.payload },
      };

    case referralActionTypes.SET_REFERRAL_PREDICTION_LIST_FILTERS:
      return {
        ...state,
        predictionFilterOptions: { ...state.predictionFilterOptions, ...action.payload },
      };

    case referralActionTypes.SET_REFERRAL_LIST_RIBBON_FILTERS:
      return {
        ...state,
        ribbonFilters: action.payload,
      };

    case referralActionTypes.SET_REFERRAL_LIST_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };

    case referralActionTypes.SET_REFERRAL_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };

    case referralActionTypes.SET_REFERRAL_RISKS:
      return {
        ...state,
        risks: action.payload,
      };

    case referralActionTypes.SET_REFERRAL_PREDICTIONS:
      return {
        ...state,
        predictions: action.payload,
      };

    case referralActionTypes.SET_DISCHARGE_DESTINATION: {
      const { destinationId, destinationName } = action.payload;

      return {
        ...state,
        referralData: {
          ...state.referralData,
          destinationId,
          destinationName,
        },
      };
    }

    case referralActionTypes.SET_SURGERY_DATE: {
      return {
        ...state,
        referralData: {
          ...state.referralData,
          surgeryDate: action.payload,
        },
      };
    }

    case referralActionTypes.SET_PROCEDURE_DISPLAY_NAME: {
      return {
        ...state,
        referralData: {
          ...state.referralData,
          procedureDisplayName: action.payload,
        },
      };
    }

    case referralActionTypes.SET_ADMISSION_TIME: {
      return {
        ...state,
        referralData: {
          ...state.referralData,
          admissionTime: action.payload,
        },
      };
    }

    case referralActionTypes.SEND_GP_REFERRAL_LIST_REQUEST: {
      return {
        ...state,
        gpReferralListRequest: action.payload,
      };
    }

    case referralActionTypes.SET_PENDING_APPOINTMENT: {
      return {
        ...state,
        pendingAppointment: action.payload,
      };
    }

    case referralActionTypes.SET_PENDING_CANCELLATION_REASON: {
      return {
        ...state,
        pendingCancellationReason: action.payload,
      };
    }

    case referralActionTypes.SET_ADVICE_SENT: {
      return {
        ...state,
        referralData: {
          ...state.referralData,
          adviceSent: 1,
        },
      };
    }

    case referralActionTypes.SET_SCHEDULE_ADVICE: {
      return {
        ...state,
        referralData: {
          ...state.referralData,
          adviceScheduled: action.payload,
        },
      };
    }

    case referralActionTypes.SET_ADVICE: {
      return {
        ...state,
        referralData: {
          ...state.referralData,
          advice: action.payload,
        },
      };
    }

    case referralActionTypes.SET_REFERRAL_PREP: {
      return {
        ...state,
        referralPrep: action.payload,
      };
    }

    default:
      checkUnreachable(type);
      return state;
  }
};

export { alertReducer, localeReducer, referralReducer };
