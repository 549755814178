import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { Text } from 'preact-i18n';
import { useEffect, useState } from 'preact/hooks';
import { useDispatchAlert } from '../../hooks';
import { uploadFileCheck } from '../../lib/fileUtils';

import sharedConstants from '../../shared-constants';

const useStyles = makeStyles(() => ({
  allowedTypesContainer: {
    display: 'inline',
    marginLeft: 12,
    marginRight: 12,
  },
}));

type FilesUploadProps = {
  onFilesChange: (files: File[]) => void;
  multiple?: boolean;
  initialFilesList?: File[];
  allowedFileTypes?: string;
  buttonText?: string;
  disabled?: boolean;
};

function FilesUpload({
  onFilesChange,
  multiple = true,
  initialFilesList,
  allowedFileTypes,
  buttonText = 'Upload files',
  disabled = false,
}: Readonly<FilesUploadProps>) {
  const [files, setFiles] = useState(initialFilesList ?? []);

  const dispatchAlert = useDispatchAlert();
  const classes = useStyles();

  useEffect(() => {
    setFiles(initialFilesList ?? []);
  }, [initialFilesList]);

  const handleFileChange = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    const filesList = [...e.target.files];

    const uploadableFiles = filesList.filter((file) => {
      const { type, message, messageKey } = uploadFileCheck(file, { allowedFileTypes });

      if (type !== 'error') {
        return file;
      }

      dispatchAlert(messageKey ?? message);
      return false;
    });

    const allFiles = multiple ? [...files, ...uploadableFiles] : uploadableFiles;
    onFilesChange(allFiles);
    setFiles(allFiles);
  };

  const handleFileRemove = (idx: number) => {
    const filesList = [...files];
    filesList.splice(idx, 1);

    onFilesChange(filesList);
    setFiles(filesList);
  };

  const allowedTypes = allowedFileTypes ?? sharedConstants.allowedFileTypes.join(', ');

  return (
    <>
      <div>
        <Button variant="outlined" color="secondary" component="label" disabled={disabled}>
          <Text id="components.fileUpload.uploadFiles">{buttonText}</Text>
          <input type="file" hidden onChange={handleFileChange} multiple={multiple} />
        </Button>
        <Typography className={classes.allowedTypesContainer} color="textSecondary">
          <Text id="components.fileUpload.allowedTypes">
            {`Allowed file types: ${allowedTypes}`}
          </Text>
        </Typography>
      </div>
      <List component="nav" aria-label="files list">
        {files?.map(({ name, size }, idx) => (
          <>
            <ListItem key={`${name}-${size}`} button>
              <ListItemText primary={`${name}`} secondary={`${(size / 1024).toFixed(1)}KB`} />
              <Tooltip title="Unselect file">
                <IconButton aria-label="unselect" onClick={() => handleFileRemove(idx)}>
                  <HighlightOff />
                </IconButton>
              </Tooltip>
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </>
  );
}

export default FilesUpload;
