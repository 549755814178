import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menuItem: {
    '& li': {
      paddingLeft: 6,
      paddingRight: 30,
    },
  },
}));

export default useStyles;
