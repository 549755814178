/* eslint-disable no-param-reassign */
import { ownerDocument, ownerWindow } from '@material-ui/core';

// Source:
// https://github.com/mui/material-ui/blob/master/packages/mui-base/src/ModalUnstyled/ModalManager.ts

function getPaddingRight(element: HTMLElement) {
  return parseInt(ownerWindow(element).getComputedStyle(element).paddingRight, 10) || 0;
}

function getScrollbarSize(doc: Document) {
  const documentWidth = doc.documentElement.clientWidth;
  return Math.abs(window.innerWidth - documentWidth);
}

type RestoreStyleItem = {
  value: any;
  property: string;
  el: HTMLElement;
};

/**
 * Hides scrollbar
 * @param container Dialog container
 * @returns Function to restore scrollbar
 */
function removeScrollbar(container: HTMLElement) {
  const restoreStyle: RestoreStyleItem[] = [];

  // Compute the size before applying overflow hidden to avoid any scroll jumps.
  const scrollbarSize = getScrollbarSize(ownerDocument(container));
  restoreStyle.push({
    value: container.style.paddingRight,
    property: 'padding-right',
    el: container,
  });

  // Use computed style, here to get the real padding to add our scrollbar width.
  container.style.paddingRight = `${getPaddingRight(container) + scrollbarSize}px`;

  const scrollContainer = ownerDocument(container).body;

  // Block the scroll even if no scrollbar is visible to account for mobile keyboard
  // screensize shrink.
  restoreStyle.push(
    {
      value: scrollContainer.style.overflow,
      property: 'overflow',
      el: scrollContainer,
    },
    {
      value: scrollContainer.style.overflowX,
      property: 'overflow-x',
      el: scrollContainer,
    },
    {
      value: scrollContainer.style.overflowY,
      property: 'overflow-y',
      el: scrollContainer,
    },
  );

  scrollContainer.style.overflow = 'hidden';

  const restore = () => {
    restoreStyle.forEach(({ value, el, property }) => {
      if (value) {
        el.style.setProperty(property, value);
      } else {
        el.style.removeProperty(property);
      }
    });
  };

  return restore;
}

export default removeScrollbar;
