import { AsType } from '../tsUtils';

const PatientEmailState = {
  /** Last email successfully sent, and email hasn't changed */
  Success: 'success',

  /** Last email failed to send, and email hasn't changed */
  Failure: 'failure',

  /** Email has changed since last email was sent */
  Updated: 'updated',

  /** No emails have yet been sent */
  None: 'none',
} as const;

export type PatientEmailStateType = AsType<typeof PatientEmailState>;
export default PatientEmailState;
