module.exports = Object.freeze({
  // General
  general: [
    'drinksAlcohol',
    'currentDrugs',
    'pastDrugs',
    'smoke',
    'stopSmoke',
    'livesAlone',
    'accompaniment',
  ],
  drinksAlcohol: 'Drinks alcohol',
  currentDrugs: 'Current use of recreational drugs',
  pastDrugs: 'Past use of recreational drugs',
  smoke: 'Smokes cigarettes',
  stopSmoke: 'Stopped smoking cigarettes',
  livesAlone: 'Lives alone',
  accompaniment: 'Nobody to accompany them overnight',

  locales: ['en-AU'],

  // fileupload config
  limitUploadFileSize: 2097152,
  ableToUploadFilesMessage: 'Files can be uploaded.',
  fileSizeOverMessage: 'File size is over the limit. (2MB)',
  fileTypeNotAllowedMessage: 'Current File type is not allowed.',
  allowedFileTypes: ['pdf', 'png', 'jpg', 'docx', 'jpeg', 'txt'],

  // patient photo check config
  allowedPatientPhotoTypes: ['jpeg', 'jpg', 'png'],
  limitPhotoFileSize: 512000,

  // tests
  testResults: {
    normal: 'Acceptable',
    abnormal: 'Unacceptable',
  },

  systemTimezone: 'Australia/Sydney',

  wording: {
    notSet: 'Not set',
  },

  trafficLight: {
    green: '#19867B',
    orange: '#F5A623',
    red: '#FF1744',
    blueText: '#2196F3',
  },
});
