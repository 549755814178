import { Select } from '@material-ui/core';
import { ColumnId } from '../referralTableColumn';

type NumberSelectFilterProps = {
  cellId: ColumnId;
  value?: number | null;
  onFilterChange: (cellId: ColumnId, value: number | null) => void;
};

function NumberSelectFilter({ cellId, value, onFilterChange }: NumberSelectFilterProps) {
  return (
    <Select
      name={`${cellId}Filter`}
      fullWidth
      style={{ maxWidth: '200px', marginTop: '4px' }}
      native
      margin="dense"
      variant="outlined"
      value={value ?? ''}
      onChange={(e) => {
        const numOption = (e.target as HTMLInputElement).value;
        onFilterChange(cellId, numOption === '' ? null : +numOption);
      }}
    >
      <option aria-label="None" value="" />
      <option value={1}>1</option>
      <option value={2}>2</option>
      <option value={3}>3</option>
    </Select>
  );
}

export default NumberSelectFilter;
