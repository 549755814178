import { Tooltip } from '@material-ui/core';
import CommsFailedIcon from '../../../../iconComponents/commsFailedIcon';
import CommsSuccessIcon from '../../../../iconComponents/commsSuccessIcon';
import CommsUpdatedIcon from '../../../../iconComponents/commsUpdatedIcon';
import PatientEmailState, { PatientEmailStateType } from '../../../../lib/types/patientEmailState';
import useStyles from './styles';

type CommStatusProps = {
  patientEmailState: PatientEmailStateType;
};

function CommStatusIcon({ patientEmailState }: Readonly<CommStatusProps>) {
  const classes = useStyles();

  let icon = null;
  let warningText = '';

  if (patientEmailState === PatientEmailState.Success) {
    icon = <CommsSuccessIcon alt="Communication status" className={classes.referralIcon} />;
    warningText = 'Email sent successfully';
  }

  if (patientEmailState === PatientEmailState.Failure) {
    icon = <CommsFailedIcon alt="Communication status" className={classes.referralIcon} />;
    warningText = 'Email bounced (possibly invalid email address)';
  }

  if (patientEmailState === PatientEmailState.Updated) {
    icon = <CommsUpdatedIcon alt="Communication status" className={classes.referralIcon} />;
    warningText = 'Email address recently updated';
  }

  if (icon == null) {
    return null;
  }

  return (
    <Tooltip title={warningText} arrow>
      {icon}
    </Tooltip>
  );
}

export default CommStatusIcon;
