import { PartialRecord } from '../../../lib/tsUtils';
import Feature from '../../../lib/types/feature';
import { ColumnId } from '../referralTableColumn';

const featureColumnMap: PartialRecord<Feature, ColumnId> = {
  [Feature.REHABILITATION]: 'rehab',
  [Feature.PREHABILITATION]: 'prehab',
  [Feature.REFERRAL_RISK_COLUMN]: 'riskLevel',
  [Feature.WAITLIST_CHECK_IN]: 'followUp',
  [Feature.POST_OP_HQ]: 'postOp',
};

export const columnsDefault: ColumnId[] = [
  'name',
  'ur',
  'state',
  'surgeryDate',
  'triageCategory',
  'createdAt',
];

export const columnsFeatures = (featureList: Feature[]) => {
  const columns: ColumnId[] = [];

  featureList.forEach((feature) => {
    const column = featureColumnMap[feature];
    if (column) {
      columns.push(column);
    }
  });
  return columns;
};
