import { SvgIcon } from '@material-ui/core';

/**
 * @param {SvgIconProps} props
 */
function ContactPrefPhoneIcon(props) {
  return (
    <SvgIcon {...props}>
      <mask
        id="mask0_2074_100"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2074_100)">
        <path
          d="M11.05 15L8.25 12.2L9.65 10.8L11.05 12.2L14.6 8.65L16 10.05L11.05 15ZM7 23C6.45 23 5.97917 22.8042 5.5875 22.4125C5.19583 22.0208 5 21.55 5 21V3C5 2.45 5.19583 1.97917 5.5875 1.5875C5.97917 1.19583 6.45 1 7 1H17C17.55 1 18.0208 1.19583 18.4125 1.5875C18.8042 1.97917 19 2.45 19 3V21C19 21.55 18.8042 22.0208 18.4125 22.4125C18.0208 22.8042 17.55 23 17 23H7ZM7 20V21H17V20H7ZM7 18H17V6H7V18ZM7 4H17V3H7V4Z"
          fill="inherit"
        />
      </g>
    </SvgIcon>
  );
}

export default ContactPrefPhoneIcon;
