import { GenericError, User } from '@auth0/auth0-react';
import { AppError } from '../components/errorBoundary/errorHandlingUtils';
import { RoleNameType } from '../lib/types/roleName';

export const ROLE_NAMESPACE = 'https://atidia.health/roles';

export type POPUser = User & {
  [ROLE_NAMESPACE]: RoleNameType[];
};

type Method = 'GET' | 'PUT' | 'POST' | 'DELETE';

export type AllowedOptions = Omit<RequestInit, 'body' | 'method' | 'headers'>;

export const makeInsecureOptions = (
  method: Method,
  token: string | null,
  data?: Record<string, any>,
  reqOptions?: AllowedOptions,
) => ({
  ...(reqOptions ?? {}),
  method,
  headers: new Headers({
    'content-type': 'application/json',
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  }),
  ...(data ? { body: JSON.stringify(data) } : {}),
});

export const makeOptions = (
  method: Method,
  token: string,
  data?: Record<string, any>,
  reqOptions?: AllowedOptions,
) => makeInsecureOptions(method, token, data, reqOptions);

export const makeInsecureOptionsFormData = (
  method: Method,
  token: string | null,
  data: FormData,
  reqOptions?: AllowedOptions,
) => ({
  ...(reqOptions ?? {}),
  method,
  headers: token ? new Headers({ Authorization: `Bearer ${token}` }) : undefined,
  body: data,
});

export const makeOptionsFormData = (
  method: Method,
  token: string,
  data: FormData,
  reqOptions?: AllowedOptions,
) => makeInsecureOptionsFormData(method, token, data, reqOptions);

export const makeQueryString = (queryParams?: Record<string, string>) => {
  if (!queryParams || Object.keys(queryParams).length === 0) {
    return '';
  }

  return `?${new URLSearchParams(queryParams).toString()}`;
};

export const checkResponseForErrors = async (response: Response) => {
  if (response.ok) {
    return response;
  }

  // Try to extract error details from response
  const responseDetails = await response.json().catch(() => null);
  const errors = responseDetails?.errors;
  if (Array.isArray(errors) && errors.length > 0) {
    throw new AppError({
      title: errors[0].title,
      detail: errors[0].detail,
      status: response.status,
    });
  }

  if (response.status === 404) {
    throw new AppError({
      title: 'Not found',
      detail: `The requested resource was not found at ${response.url}`,
      status: 404,
    });
  }

  // If we can't extract error details, just throw the status code
  throw new AppError({
    title: `${response.status}`,
    detail: response.statusText,
    status: response.status,
  });
};

/**
 * Convert response to JSON, while handling an empty response
 *
 * Should be used on responses that can sometimes return an empty body (e.g PUT, POST) but not those that should always return a body (e.g GET)
 */
export const responseToOptionalJSON = async (response: Response) => {
  const cType = response.headers.get('content-type');
  if (cType?.startsWith('application/json;')) {
    return response.json();
  }
  return response.text();
};

// is this necessary?
export const handleErrResponse = async (err: any) => {
  let responseDetails;
  let errorDetail = '';
  let errTitle = '';

  if (err instanceof Response) {
    responseDetails = await err.json();
    errTitle = responseDetails?.errors[0]?.title;
    errorDetail = responseDetails?.errors[0]?.detail;
  }

  if (err.name === 'AbortError') {
    throw err;
  }

  if (err instanceof AppError) {
    throw err;
  }

  throw new AppError({
    title: errTitle || err.message,
    detail: errorDetail,
    status: err.status,
  });
};

const Auth0ErrorState = {
  Unauthorised: 'Unauthorised',
  Blocked: 'Account is blocked',
  AccessDenied: 'Access denied',
  RequiresVerification: 'Account requires verification',
  TooManyAttempts: 'Too many login attempts',
  TooManyRequests: 'Too many requests',
  EmailRequiresVerification: 'Email requires verification',
} as const;

/**
 * Extract auth0 error message from error object
 * @param inputErr Error returned from useAuth0 hook
 * @returns Readable error string, or null
 */
// https://auth0.com/docs/api/authentication?javascript#standard-error-responses
export const getAuth0Error = (inputErr: any) => {
  const error = inputErr as GenericError;
  if (!error?.error) {
    return null;
  }

  if (error.error === 'requires_verification') {
    return Auth0ErrorState.RequiresVerification;
  }

  if (error.error === 'too_many_attempts') {
    return Auth0ErrorState.TooManyAttempts;
  }

  if (error.error === 'too_many_requests') {
    return Auth0ErrorState.TooManyRequests;
  }

  if (error.error_description === 'Please verify your email before logging in') {
    return Auth0ErrorState.EmailRequiresVerification;
  }

  if (error.error === 'unauthorized') {
    if (error.error_description === 'user is blocked') {
      return Auth0ErrorState.Blocked;
    }
    return Auth0ErrorState.Unauthorised;
  }

  if (error.error === 'access_denied') {
    return Auth0ErrorState.AccessDenied;
  }

  return error.message ?? 'An error occurred';
};
