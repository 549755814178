import { useEffect, useState } from 'preact/hooks';
import { Resolver, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useDispatchAlert } from '../../hooks';
import { validateEmail } from '../../lib/utils';
import usePatientDetailsFormService from '../../services/usePatientDetailsFormService';
import AlertMultiline from '../alertMultiline';
import CentredLoader from '../centredLoader';
import InfoForm from './infoForm';

type FormValues = {
  email: string;
  kinMobileNumber: string;
  nokFirstName: string;
  nokLastName: string;
  nokEmail: string;
  phoneOnly: boolean;
  sendToNok: boolean;
};
type FormKeys = keyof FormValues;

enum FormState {
  None,
  Submitted,
  Error,
  /** Not valid, but not submitted */
  Invalid,
}

const ERR_REQUIRED = { type: 'required' };

const setError = (errors: { [key in FormKeys]?: any }, fieldName: FormKeys, valid: boolean) => {
  if (!valid) {
    // eslint-disable-next-line no-param-reassign
    errors[fieldName] = ERR_REQUIRED;
  }
  return errors;
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: { [key in FormKeys]?: any } = {};

  if (values.sendToNok) {
    setError(errors, 'nokFirstName', !!values.nokFirstName);
    setError(errors, 'nokLastName', !!values.nokLastName);
    setError(errors, 'nokEmail', validateEmail(values.nokEmail.trim()));
  }

  if (!values.phoneOnly) {
    setError(errors, 'email', validateEmail(values.email.trim()));
  }

  return {
    values: Object.keys(errors).length > 0 ? {} : values,
    errors,
  };
};

type FormInfo = {
  isSubmitted: boolean;
  patientName: string;
  logoUrl: string | null;
};

function PatientInfoForm() {
  const { formKey } = useParams();
  if (!formKey) {
    throw new Error('Form key is required');
  }

  const dispatchAlert = useDispatchAlert();
  const { updatePatientFromSmsForm, getBasicPatientSmsFormInfo } = usePatientDetailsFormService();
  const [formState, setFormState] = useState<FormState>(FormState.None);
  const [loading, setLoading] = useState(false);

  const [basicFormInfo, setBasicFormInfo] = useState<FormInfo>({
    isSubmitted: false,
    patientName: '',
    logoUrl: '',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    clearErrors,
  } = useForm({
    defaultValues: {
      email: '',
      kinMobileNumber: '',
      nokFirstName: '',
      nokLastName: '',
      nokEmail: '',
      phoneOnly: false,
      sendToNok: false,
    },
    resolver,
  });

  useEffect(() => {
    setLoading(true);

    getBasicPatientSmsFormInfo(formKey)
      .then((info) => {
        if (!info.isValid && !info.isSubmitted) {
          setFormState(FormState.Invalid);
        }
        setBasicFormInfo(info);
      })
      .catch(() => {
        dispatchAlert('Could not fetch your details');
        setFormState(FormState.Error);
      })
      .finally(() => setLoading(false));
  }, [dispatchAlert, formKey]);

  const onSubmit = (values: FormValues) => {
    setLoading(true);

    const { email, sendToNok, phoneOnly, nokFirstName, nokLastName, nokEmail, kinMobileNumber } =
      values;

    const requestData = {
      phoneOnly,
      kinMobileNumber,
      patientEmailAddress: email.trim(),
      kinName: sendToNok ? `${nokFirstName} ${nokLastName}` : '',
      kinEmailAddress: sendToNok ? nokEmail.trim() : '',
      kinCorrespondence: sendToNok,
    };

    updatePatientFromSmsForm(formKey, requestData)
      .then(() => setFormState(FormState.Submitted))
      .catch(() => {
        dispatchAlert('Failed to update your details');
        setFormState(FormState.Error);
      })
      .finally(() => setLoading(false));
  };

  if (formState === FormState.Error) {
    return (
      <AlertMultiline
        title="Something went wrong. Please check if the link is valid."
        severity="error"
        standalone
      />
    );
  }

  if (formState === FormState.Invalid) {
    return (
      <AlertMultiline title="This link is no longer valid" severity="error" standalone>
        If you believe this is an error please contact the surgery clinic
      </AlertMultiline>
    );
  }

  if (formState === FormState.Submitted) {
    return <AlertMultiline title="Your details have been updated" severity="success" standalone />;
  }

  if (basicFormInfo.isSubmitted) {
    return (
      <AlertMultiline title="This form has already been submitted" severity="warning" standalone>
        If your details have changed please contact the surgery clinic
      </AlertMultiline>
    );
  }

  return (
    <>
      <CentredLoader loading={loading} />
      {!loading && (
        <InfoForm
          control={control}
          watch={watch}
          clearErrors={clearErrors}
          handleSubmit={handleSubmit(onSubmit)}
          patientName={basicFormInfo.patientName}
          logoUrl={basicFormInfo.logoUrl}
          errors={errors}
        />
      )}
    </>
  );
}

export default PatientInfoForm;
