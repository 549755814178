import { Box } from '@material-ui/core';
import AlertMultiline from '../../../components/alertMultiline';
import { ReferralListItem } from '../../../lib/types/referralListItem';

type OptOutAlertProps = {
  referral: {
    sendReminders: ReferralListItem['sendReminders'];
    patient: {
      phoneOnly: ReferralListItem['patient']['phoneOnly'];
      sendCorrespondence: ReferralListItem['patient']['phoneOnly'];
      optOutReason: ReferralListItem['patient']['optOutReason'];
    };
    remindersCancelReason: ReferralListItem['remindersCancelReason'];
  };
};

function OptOutAlert({ referral }: OptOutAlertProps) {
  /**
   * Note - order is important here
   * Patient opt-outs should supercede reminder opt-outs
   */
  const { patient } = referral;

  if (patient.phoneOnly) {
    return (
      <Box mb={2}>
        <AlertMultiline
          severity="warning"
          title="Patient indicated that they do not have an email address"
        />
      </Box>
    );
  }

  if (!patient.sendCorrespondence) {
    return (
      <Box mb={2}>
        <AlertMultiline severity="warning" title="Patient opted out of emails">
          {patient.optOutReason != null && `Opt-out reason: "${patient.optOutReason}"`}
        </AlertMultiline>
      </Box>
    );
  }

  if (!referral.sendReminders) {
    return (
      <Box mb={2}>
        <AlertMultiline severity="warning" title="Patient opted out of questionnaire reminders">
          {referral.remindersCancelReason != null && `Reason: "${referral.remindersCancelReason}"`}
        </AlertMultiline>
      </Box>
    );
  }

  return null;
}

export default OptOutAlert;
