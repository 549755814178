import { useCallback } from 'react';
import useInsecureAPI from '../auth/useInsecureAPI';

type SmsFormDetailsResponse = {
  isSubmitted: boolean;
  isValid: boolean;
  patientName: string;
  logoUrl: string | null;
};

function usePatientDetailsFormService() {
  const { put, get } = useInsecureAPI('patient-details-form');

  const getBasicPatientSmsFormInfo = useCallback(
    (formKey: string) => get<SmsFormDetailsResponse>(`${formKey}`),
    [get],
  );

  const updatePatientFromSmsForm = useCallback(
    (formKey: string, payload: any) => put(`${formKey}`, payload),
    [put],
  );

  return {
    getBasicPatientSmsFormInfo,
    updatePatientFromSmsForm,
  };
}

export default usePatientDetailsFormService;
