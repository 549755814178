import { Select, TextField } from '@material-ui/core';
import { ColumnId } from '../referralTableColumn';

type FilterProps<T extends string | number | symbol> = {
  cellId: ColumnId;
  value: T | undefined | null;
  onFilterChange: (cellId: ColumnId, value?: string) => void;
  filterOptions: Record<T, string>;
};

// Filter for a column based on a set of options with a dictionary mapping option value to display label
function OptionsFilter<T extends string | number | symbol>({
  cellId,
  value,
  onFilterChange,
  filterOptions,
}: FilterProps<T>) {
  return (
    <Select
      name={`${cellId}Filter`}
      fullWidth
      style={{ maxWidth: '200px', marginTop: '4px' }}
      native
      margin="dense"
      variant="outlined"
      value={value ?? ''}
      onChange={(e) => {
        const option = (e.target as HTMLInputElement).value;
        onFilterChange(cellId, option || undefined);
      }}
      renderInput={(params: any) => <TextField {...params} margin="dense" />}
    >
      <option aria-label="None" />
      {Object.entries(filterOptions).map(([key, display]) => (
        <option value={key} key={key}>
          {display}
        </option>
      ))}
    </Select>
  );
}

export default OptionsFilter;
