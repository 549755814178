import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { ComponentChildren } from 'preact';
import { useNavigate } from 'react-router-dom';
import useCustomer from '../hooks/useCustomer';

type Props = {
  children: ComponentChildren;
};

function POPAuthProvider({ children }: Props) {
  const navigate = useNavigate();
  const { orgId } = useCustomer();

  const domain = process.env.PREACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.PREACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.PREACT_APP_AUTH0_AUDIENCE;

  const redirectUri = `${window.location.origin}/callback`;

  const onRedirectCallback = (appState?: AppState) => {
    if (appState?.orgName) {
      const { orgName, returnTo } = appState;
      window.location.href = `https://${orgName}.atidia.health${returnTo}`;
      return;
    }

    const returnTo = appState?.returnTo ?? window.location.pathname;
    if (returnTo === '/callback') {
      navigate('/');
    } else {
      navigate(returnTo);
    }
  };

  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  if (!orgId) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: redirectUri,
        organization: orgId,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      // Ensure user remains logged in if a new tab is opened or page is refreshed
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}

export default POPAuthProvider;
