import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { StateUpdater } from 'preact/hooks';
import { processErrorResponseInFunctional } from '../../../components/errorBoundary/errorHandlingUtils';
import { ReferralListItem } from '../../../lib/types/referralListItem';
import useReferralService from '../../../services/useReferralService';

type DeleteReferralDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedReferrals: ReferralListItem[];
  onError: StateUpdater<any>;
  onUpdate?: () => void;
};

function DeleteReferralDialog({
  open,
  onClose,
  selectedReferrals,
  onError,
  onUpdate = () => null,
}: DeleteReferralDialogProps) {
  const referralService = useReferralService();

  const handleClose = () => {
    onClose();
  };

  const handleRemoveReferral = () => {
    referralService
      .deleteReferrals(selectedReferrals.map(({ gpReferralUuid }) => gpReferralUuid))
      .catch((e) => processErrorResponseInFunctional(e, onError))
      .finally(() => {
        onUpdate();
        onClose();
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="confirm-delete-referrals"
    >
      <DialogTitle id="confirm-delete-referrals-title">Confirm Delete</DialogTitle>
      <DialogContent style={{ fontSize: '1rem' }}>
        <DialogContentText>
          <Typography>Are you sure to remove selected referrals?</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
        <Button color="primary" variant="contained" onClick={handleRemoveReferral}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteReferralDialog;
