import { AppError } from '../components/errorBoundary/errorHandlingUtils';
import { AsType } from '../lib/tsUtils';

export const Alert = {
  COVID: {
    type: 'covid',
    issueItem: 'COVID-19 positive',
    system: 'otherHealth',
    conditionKey: 'hadCovid',
  },
  DIABETIC: {
    type: 'insulin',
    issueItem: 'Has diabetes',
    system: 'endocrine',
    conditionKey: 'hasDiabetes',
  },
  BLOOD_THINNERS: {
    type: 'bloodThinners',
    issueItem: 'Takes blood thinners',
    system: 'cardiology',
    conditionKey: 'takesBloodThinners',
  },
  AIRWAY: {
    type: 'airway',
    issueItem: 'Has difficult airway',
    system: 'anaesthesia',
    conditionKey: 'hasDifficultAirway',
  },
  HYPERTHERMIA: {
    type: 'hyperthermia',
    issueItem: 'Has malignant hyperthermia',
    system: 'anaesthesia',
    conditionKey: 'malignantHyperthermia',
  },
  ALLERGIES: {
    type: 'allergies',
    issueItem: 'Has allergies',
    system: 'general',
    conditionKey: null,
  },
  LANGUAGE: {
    type: 'interpreterLanguage',
    issueItem: 'Requires interpreter',
    system: 'general',
    conditionKey: 'interpreterRequired',
  },
  LIVES_ALONE: {
    type: 'livesAlone',
    issueItem: 'Lives alone',
    system: 'general',
    conditionKey: 'livesAlone',
  },
  INFECTION: {
    type: 'ongoingInfections',
    issueItem:
      'Has an ongoing infection: tuberculosis (TB), hepatitis B, hepatitis C, MRSA, VRE, HIV',
    system: 'otherHealth',
    conditionKey: 'ongoingInfections',
  },
  UNACCOMPANIED: {
    type: 'accompaniment',
    issueItem: 'Nobody to accompany them overnight',
    system: 'general',
    conditionKey: 'accompaniment',
  },
  PREGNANT: {
    type: 'pregnant',
    issueItem: 'Pregnant',
    system: 'otherHealth',
    conditionKey: 'pregnancyOrBreastfeeding',
  },
  BREASTFEEDING: {
    type: 'breastfeeding',
    issueItem: 'Breastfeeding',
    system: 'otherHealth',
    conditionKey: 'pregnancyOrBreastfeeding',
  },
  INFECTION_RISK: {
    type: 'infectionRisk',
    issueItem: 'Potential infection risk',
    system: 'otherHealth',
    conditionKey: null,
  },
  GLP1AGONIST: {
    type: 'takesGLP1Agonist',
    issueItem: 'Takes GLP-1 agonist',
    system: 'endocrine',
    conditionKey: null,
  },
} as const;

export type AlertType = AsType<typeof Alert>;

export type AlertName = AlertType['type'];

export const fromName = (alertName: AlertName) => {
  const alertKeys = Object.keys(Alert) as (keyof typeof Alert)[];
  const alertKey = alertKeys.find((key) => Alert[key].type === alertName);
  if (!alertKey) {
    throw new AppError({
      title: 'Invalid alert',
      detail: `The alert type ${alertName} was not one of the known alert types`,
    });
  }
  return Alert[alertKey];
};
