import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Divider, IconButton, Menu, MenuItem, Popover, Toolbar } from '@material-ui/core';
import {
  AccountBox,
  AccountCircle,
  Announcement,
  Assignment,
  ExitToApp,
  Settings,
  Speed,
  ViewList,
} from '@material-ui/icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DateRange from '@material-ui/icons/DateRange';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MoreIcon from '@material-ui/icons/MoreVert';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PeopleIcon from '@material-ui/icons/People';
import Publish from '@material-ui/icons/Publish';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import WarningIcon from '@material-ui/icons/Warning';
import { useEffect, useState } from 'preact/hooks';
import { useLocation, useNavigate } from 'react-router';
import { POPUser, ROLE_NAMESPACE } from '../../auth/apiUtils';
import useCustomer from '../../hooks/useCustomer';
import Feature from '../../lib/types/feature';
import RoleName, { RoleNameType } from '../../lib/types/roleName';
import ImportDialog from '../../routes/referralList/importDialog';
import useFeatureSwitchService from '../../services/useFeatureSwitchService';
import useHQSettingsService from '../../services/useHQSettingsService';
import SettingsMenu from '../dialogs/settingsMenu';
import SuperSettingsMenu from '../dialogs/superSettingsMenu';
import UserProfileDialog from '../dialogs/userProfileDialog';
import RoutePath from '../routePaths';
import HeaderLink from './headerLink';
import NavbarMenuItem from './navbarMenuItem';
import ReferralCreateMenu from './referralCreate';
import useStyles from './styles';

const AssetPath = {
  brandingLines: '/assets/icons/BrandingLines.png',
  logoDefault: '/assets/icons/Colour-Negative-onLight.png',
} as const;

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { getPreOpSettings } = useHQSettingsService();
  const { getEnabledFeatures } = useFeatureSwitchService();
  const { orgSettings } = useCustomer();

  const [prediction, setPrediction] = useState(false);
  const [endoscopy, setEndoscopy] = useState(false);
  const [autoDispatch, setAutoDispatch] = useState(false);
  const [featureList, setFeatureList] = useState<Feature[]>([]);
  const [anchorProfileEl, setAnchorProfileEl] = useState<HTMLElement | null>(null);
  const [anchorSettingsEl, setAnchorSettingsEl] = useState<HTMLElement | null>(null);
  const [anchorSuperSettingsEl, setAnchorSuperSettingsEl] = useState<HTMLElement | null>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<HTMLElement | null>(null);
  const [importDialog, setImportDialog] = useState(false);

  const [userRoles, setUserRoles] = useState<RoleNameType[]>([]);

  const { isAuthenticated, user, error, loginWithRedirect, logout } = useAuth0<POPUser>();

  const accessDenied = error?.message === 'Access denied';

  const activeUrl = location.pathname;

  useEffect(() => {
    setPrediction(featureList.includes(Feature.ML_SCHEDULING));
    setEndoscopy(featureList.includes(Feature.ENDOSCOPY));
  }, [featureList]);

  useEffect(() => {
    const getRolesFromClaims = () => {
      if (isAuthenticated && user) {
        setUserRoles(user[ROLE_NAMESPACE] ?? []);
        getPreOpSettings().then((res) => setAutoDispatch(res.autoDispatch));
        getEnabledFeatures().then(setFeatureList);
      }
    };
    getRolesFromClaims();
  }, [getEnabledFeatures, getPreOpSettings, isAuthenticated, user]);

  const classes = useStyles();
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';

  const isOpenUserProfile = Boolean(anchorProfileEl);
  const profileId = isOpenUserProfile ? 'profile-popover' : undefined;

  const isOpenSettings = Boolean(anchorSettingsEl);
  const settingsId = isOpenSettings ? 'settings-popover' : undefined;

  const isOpenSuperSettings = Boolean(anchorSuperSettingsEl);
  const superSettingsId = isOpenSuperSettings ? 'super-settings-popover' : undefined;

  const homepageUrl = orgSettings?.homepageUrl ?? 'https://atidia.health';
  const logoUrl = orgSettings?.logoUrl ?? AssetPath.logoDefault;

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.TargetedEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const makeLinkHandler = (linkRoute: string) => () => {
    handleMobileMenuClose();
    return navigate(linkRoute);
  };

  const toNewReferral = makeLinkHandler('/referral/new/');
  const toManageUsers = makeLinkHandler('/users/list');
  const toManageReferral = makeLinkHandler('/manage/referral');
  const toManageReminders = makeLinkHandler('/manage/reminders');
  const toManageRiskAlerts = makeLinkHandler('/manage/alerts-risks');
  const toManageNotifications = makeLinkHandler('/manage/notifications');
  const toManagePredictions = makeLinkHandler('/manage/predictions');
  const toReferralListSettings = makeLinkHandler('/manage/referralList');
  const toHqSettings = makeLinkHandler('/manage/hq-settings');
  const toManageAdvice = makeLinkHandler('/manage/advice');

  const handleLogout = () => {
    handleMobileMenuClose();
    return logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleLogin = () => {
    handleMobileMenuClose();
    return loginWithRedirect({ appState: { returnTo: location.pathname } });
  };

  const handleImportPatients = () => {
    handleMobileMenuClose();
    setImportDialog(true);
  };

  const handleProfileClick = (event: React.TargetedEvent<HTMLElement>) => {
    setAnchorProfileEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorProfileEl(null);
  };

  const handleSettingsClick = (event: React.TargetedEvent<HTMLElement>) => {
    setAnchorSettingsEl(event.currentTarget);
  };

  const handleSuperSettingsClick = (event: React.TargetedEvent<HTMLElement>) => {
    setAnchorSuperSettingsEl(event.currentTarget);
  };

  const closeSettingsMenu = () => {
    setAnchorSettingsEl(null);
  };

  const closeSuperSettingsMenu = () => {
    setAnchorSuperSettingsEl(null);
  };

  const isPatientsActive = () => activeUrl === RoutePath.ReferralListPatients;
  const isEndoscopyActive = () => activeUrl === RoutePath.ReferralListEndoscopy;
  const isOverviewActive = () => activeUrl === RoutePath.Overview;
  const isBizAnalyticsActive = () => activeUrl === RoutePath.BusinessAnalytics;
  const isPredictionsActive = () => activeUrl === RoutePath.ReferralListPredictions;

  // Don't show patients tab if user is only an admin
  const viewPatientRoles: RoleNameType[] = [
    RoleName.Nurse,
    RoleName.Anaesthetist,
    RoleName.Surgeon,
  ];
  const showReferralLists = userRoles?.some((role) => viewPatientRoles.includes(role));

  return (
    <div className={classes.grow}>
      <AppBar color="inherit" elevation={0} position="relative" className={classes.appBar}>
        <Toolbar>
          <a href={homepageUrl} target="_blank" rel="noreferrer">
            <img className={classes.brandingLines} src={AssetPath.brandingLines} alt="Atidia" />
          </a>

          <a href={homepageUrl} target="_blank" rel="noreferrer">
            <img className={classes.headerImg} src={logoUrl} alt="Logo" />
          </a>

          <Divider orientation="vertical" className={classes.divider} />

          {isAuthenticated && (
            <>
              {userRoles.includes(RoleName.Nurse) && (
                <HeaderLink
                  title="Overview"
                  link={RoutePath.Overview}
                  active={isOverviewActive()}
                  name="nav-overview"
                />
              )}
              {userRoles.includes(RoleName.Admin) && (
                <HeaderLink
                  title="Business analytics"
                  link={RoutePath.BusinessAnalytics}
                  active={isBizAnalyticsActive()}
                  name="nav-business-analytics"
                />
              )}
              {showReferralLists && (
                <>
                  {prediction && (
                    <HeaderLink
                      title="Prediction"
                      link={RoutePath.ReferralListPredictions}
                      active={isPredictionsActive()}
                      name="nav-prediction"
                    />
                  )}
                  <HeaderLink
                    title="Patients"
                    link={RoutePath.ReferralListPatients}
                    active={isPatientsActive()}
                    name="nav-patients"
                  />
                  {endoscopy && (
                    <HeaderLink
                      title="Endoscopy"
                      link={RoutePath.ReferralListEndoscopy}
                      active={isEndoscopyActive()}
                      name="nav-endoscopy"
                      badgeText="NEW"
                    />
                  )}
                </>
              )}
            </>
          )}

          <div className={classes.grow} />

          <div className={classes.sectionDesktop}>
            {/** Only a nurse can import patients */}
            {userRoles.includes(RoleName.Nurse) && (
              <ReferralCreateMenu
                onAddNewClick={toNewReferral}
                onImportClick={handleImportPatients}
              />
            )}

            {/** Only admin can access settings */}
            {userRoles.includes(RoleName.Admin) && (
              <IconButton
                edge="end"
                aria-label="Settings"
                aria-haspopup="true"
                onClick={handleSettingsClick}
                color="inherit"
                title="Settings"
              >
                <Settings />
              </IconButton>
            )}

            {/** Only superAdmin can access super settings */}
            {userRoles.includes(RoleName.SuperAdmin) && (
              <IconButton
                edge="end"
                aria-label="Settings"
                aria-haspopup="true"
                onClick={handleSuperSettingsClick}
                color="inherit"
                title="Super settings"
              >
                <SettingsApplicationsIcon />
              </IconButton>
            )}

            <IconButton
              edge="end"
              name="btnUserProfile"
              aria-label="User Profile"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileClick}
              color="inherit"
              title="Profile"
            >
              <AccountCircle />
            </IconButton>

            <Popover
              id={profileId}
              open={isOpenUserProfile}
              anchorEl={anchorProfileEl}
              onClose={handleProfileClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <UserProfileDialog />
            </Popover>

            {/** Only admin can access settings */}
            {userRoles.includes(RoleName.Admin) && (
              <Popover
                id={settingsId}
                open={isOpenSettings}
                anchorEl={anchorSettingsEl}
                onClose={closeSettingsMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <SettingsMenu onMenuItemSelect={closeSettingsMenu} features={{ prediction }} />
              </Popover>
            )}

            {/** Only super admin can access 'super settings' */}
            {userRoles.includes(RoleName.SuperAdmin) && (
              <Popover
                id={superSettingsId}
                open={isOpenSuperSettings}
                anchorEl={anchorSuperSettingsEl}
                onClose={closeSuperSettingsMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <SuperSettingsMenu onItemSelect={closeSuperSettingsMenu} />
              </Popover>
            )}
          </div>

          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        className={classes.menuItem}
      >
        {userRoles.includes(RoleName.Admin) && (
          <>
            <NavbarMenuItem
              label="Notifications"
              icon={<NotificationsActiveIcon />}
              onClick={toManageNotifications}
            />

            <NavbarMenuItem label="Users" icon={<PeopleIcon />} onClick={toManageUsers} />

            <NavbarMenuItem
              label="Hospital configuration"
              icon={<LocalHospitalIcon />}
              onClick={toManageReferral}
            />

            <NavbarMenuItem
              label="Alerts/Risks"
              icon={<WarningIcon />}
              onClick={toManageRiskAlerts}
            />

            <NavbarMenuItem label="Reminders" icon={<DateRange />} onClick={toManageReminders} />

            {prediction && (
              <NavbarMenuItem label="Prediction" icon={<Speed />} onClick={toManagePredictions} />
            )}

            <NavbarMenuItem
              label="Referral list"
              icon={<ViewList />}
              onClick={toReferralListSettings}
            />

            <NavbarMenuItem label="Questionnaires" icon={<Assignment />} onClick={toHqSettings} />

            <NavbarMenuItem label="Advice" icon={<Announcement />} onClick={toManageAdvice} />
          </>
        )}

        {userRoles.includes(RoleName.Admin) && (
          <>
            <MenuItem onClick={toNewReferral}>
              <IconButton aria-label="New Referral" color="inherit">
                <AddCircleOutlineIcon />
              </IconButton>
              <p>New Referral</p>
            </MenuItem>

            <MenuItem onClick={handleImportPatients}>
              <IconButton aria-label="Import CSV File" color="inherit">
                <Publish />
              </IconButton>
              <p>Import Patients</p>
            </MenuItem>
          </>
        )}

        {(isAuthenticated || accessDenied) && (
          <MenuItem onClick={handleLogout}>
            <IconButton aria-label="Sign out" color="inherit">
              <ExitToApp />
            </IconButton>
            <p>Sign out</p>
          </MenuItem>
        )}
        {!isAuthenticated && !accessDenied && (
          <MenuItem onClick={handleLogin}>
            <IconButton aria-label="Sign in" color="inherit">
              <AccountBox />
            </IconButton>
            <p>Sign in</p>
          </MenuItem>
        )}
      </Menu>

      {userRoles.includes(RoleName.Nurse) && (
        <ImportDialog
          open={importDialog}
          onClose={() => setImportDialog(false)}
          autoDispatch={autoDispatch}
        />
      )}
    </div>
  );
}

export default Navbar;
