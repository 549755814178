import { Typography, makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import { PartialRecord } from '../../../lib/tsUtils';
import { ReferralRisk } from '../../../lib/types/referralRisk';
import RiskLevel from '../../../lib/types/riskLevel';
import { RiskType } from '../../../lib/types/riskType';
import { formatText } from '../../../lib/utils';
import RiskBadge from './riskBadge';

type PrehabBadgeProps = {
  prehabRisk: ReferralRisk;
  prehabRecommendations: PartialRecord<RiskType, string[]>;
};

const useStyles = makeStyles(() => ({
  recommendationList: {
    margin: 8,
    paddingLeft: 24,
  },
}));

function PrehabBadge({ prehabRisk, prehabRecommendations }: Readonly<PrehabBadgeProps>) {
  const classes = useStyles();

  const popoverContent = useMemo(() => {
    if (!prehabRisk.secondaryScores) {
      return null;
    }

    const riskTypes: RiskType[] = prehabRisk.secondaryScores.map((r) => r.name as RiskType);

    const content = riskTypes.map((riskType) => (
      <>
        <Typography variant="h4">{formatText(riskType)}</Typography>
        <ul className={classes.recommendationList}>
          {prehabRecommendations[riskType]?.map((r) => (
            <li key={r}>{r}</li>
          ))}
        </ul>
      </>
    ));

    return <div style={{ maxWidth: 400 }}>{content}</div>;
  }, [classes.recommendationList, prehabRecommendations, prehabRisk.secondaryScores]);

  // Don't display badge if there are no recommendations
  if (!prehabRisk.secondaryScores) {
    return null;
  }

  return (
    <RiskBadge
      riskLevel={RiskLevel.High}
      popoverContent={popoverContent}
      textMapping={{
        [RiskLevel.High]: 'Actionable',
      }}
    />
  );
}

export default PrehabBadge;
