import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import clsx from 'clsx';
import { globalStyles } from '../style/globalStyles';

const useStyles = makeStyles((theme) => ({
  ...globalStyles(theme),
  largeIcon: {
    fontSize: '84px',
  },
  centredText: {
    textAlign: 'center' as const,
  },
  centredBox: {
    top: '50%',
    left: '50%',
    position: 'absolute' as const,
    transform: 'translate(-50%, -50%)',
  },
}));

type Props = {
  error: string;
  extraText?: string;
  showButton?: boolean;
};

/** fall-back route (handles unroutable URLs) */
export default function FullscreenErrorPage({ error, extraText, showButton = true }: Props) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.centredBox, classes.root, classes.centredText)}>
      <Warning className={classes.largeIcon} color="error" />
      <Typography variant="h1">{error}</Typography>

      {extraText && (
        <Box mt={2}>
          <Typography variant="h3">{extraText}</Typography>
        </Box>
      )}

      {showButton && (
        <Box mt={8}>
          <Button href="/" variant="contained" color="primary">
            Return to homepage
          </Button>
        </Box>
      )}
    </div>
  );
}
