import { formatCamelCaseText } from './utils';

export const DefaultCollection = 'default';
export const PostOpCollection = 'post-op';
export const CheckInCollection = 'Waitlist check-in';
export const InfectionControlCollection = 'infectionControl';

type HqCollectionType = string;

const getCollectionDisplayName = (collectionName: string) => {
  if (collectionName === DefaultCollection) {
    return 'Health questionnaire';
  }
  if (collectionName === PostOpCollection) {
    return 'Post-op survey';
  }
  if (collectionName === InfectionControlCollection) {
    return 'Infection control survey';
  }

  return formatCamelCaseText(collectionName);
};

export { getCollectionDisplayName, HqCollectionType };
