export enum ClinicianStatus {
  None = 'none',
  Requested = 'requested',
  Received = 'received',
}

export const clinicianStatusNames: Record<ClinicianStatus, string> = {
  none: 'No review requested',
  requested: 'Review requested',
  received: 'Review received',
} as const;
