export enum ReferralAlert {
  EmailInvalid = 'emailInvalid',
  EmailNotReceived = 'emailNotReceived',
  EmailOptOut = 'emailOptOut',
  Medication = 'medication',
  AdviceUnsent = 'adviceUnsent',
}

export const referralAlertNames: Record<ReferralAlert, string> = {
  emailInvalid: 'Invalid email',
  emailNotReceived: 'Email not received',
  emailOptOut: 'Email opt-out',
  medication: 'Medication',
  adviceUnsent: 'No advice sent',
} as const;
