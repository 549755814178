import { AsType } from '../tsUtils';

const Risk = {
  // risks
  BMI: 'bmi',
  DASI: 'dasi',
  MDASI: 'mdasi',
  Age: 'age',
  Smoking: 'smoking',
  Frail: 'frail',
  METS: 'mets',
  Malnutrition: 'malnutrition',
  SleepApnoea: 'sleepApnoea',
  Alcohol: 'alcohol',

  // risks and screen
  ASA: 'asa',

  // screens
  ComplexityMetsRisk: 'complexityMetsRisk',
  FollowUp: 'followUp',
  PostOp: 'postOp',
  Prehab: 'prehab',
  Rehab: 'rehab',
  PrivateSuitable: 'privateSuitable',
  Endoscopy: 'endoscopySuitable',
  DayCase: 'dayCase',

  // copilot
  Pulmonary: 'pulmonary',
  Echocardiogram: 'echocardiogram',
} as const;

export const riskTitleMap: Record<RiskType, string> = {
  [Risk.ASA]: 'ASA',
  [Risk.DASI]: 'DASI',
  [Risk.MDASI]: 'MDASI',
  [Risk.Age]: 'Age',
  [Risk.BMI]: 'BMI',
  [Risk.Smoking]: 'Smoking',
  [Risk.Frail]: 'FRAIL',
  [Risk.METS]: 'METS',
  [Risk.Malnutrition]: 'Malnutrition',
  [Risk.ComplexityMetsRisk]: 'Northern surgery risk',
  [Risk.SleepApnoea]: 'OSA',
  [Risk.Alcohol]: 'Alcohol',
  [Risk.FollowUp]: 'Follow up',
  [Risk.PostOp]: 'Post-op',
  [Risk.Prehab]: 'Prehabilitation',
  [Risk.PrivateSuitable]: 'St. Vincents suitability',
  [Risk.Rehab]: 'Rehabilitation',
  [Risk.DayCase]: 'Broadmeadows suitability',
  [Risk.Pulmonary]: 'Pulmonary',
  [Risk.Echocardiogram]: 'Echocardiogram',
  [Risk.Endoscopy]: 'Endoscopy',
};

export type RiskType = AsType<typeof Risk>;

/**
 * List of the subset of all risks that are used as a screen.
 * Note that risks can also be screens (eg in the case of ASA)
 */
const screenTypes: RiskType[] = [
  Risk.ComplexityMetsRisk,
  Risk.FollowUp,
  Risk.PostOp,
  Risk.Prehab,
  Risk.Rehab,
  Risk.ASA,
  Risk.DayCase,
  Risk.PrivateSuitable,
  Risk.Endoscopy,
];

export const isScreenType = (risk: RiskType) => screenTypes.includes(risk);

export default Risk;
