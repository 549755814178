import { makeStyles } from '@material-ui/core';
import { riskScoreDisplayString } from '../../../../lib/riskDisplayUtils';
import { RiskScore } from '../../../../lib/types/referralRisk';

type Props = {
  riskScores: RiskScore[] | undefined;
};

const useStyles = makeStyles(() => ({
  scoresList: {
    margin: 8,
    paddingLeft: 24,
  },
}));

function RiskScores({ riskScores }: Props) {
  const classes = useStyles();

  if (!riskScores) {
    return null;
  }

  return (
    <div style={{ maxWidth: 400 }}>
      <ul className={classes.scoresList}>
        {riskScores?.map((score) => (
          <li key={score.name}>
            {score.name} {score.value && `- ${riskScoreDisplayString(score)}`}
          </li>
        ))}
      </ul>
    </div>
  );
}
export default RiskScores;
