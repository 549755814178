import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { FunctionComponent } from 'react';
import CentredLoader from '../components/centredLoader';
import { RoleNameType } from '../lib/types/roleName';
import { POPUser, ROLE_NAMESPACE } from './apiUtils';
import AuthFailure from './authFailurePage';

const onRedirecting = () => <CentredLoader loading />;

/**
 * Check that user has at least one of the provided roles
 */
const checkRoles = (user: POPUser, roles: RoleNameType | RoleNameType[]) => {
  const userRoles = user[ROLE_NAMESPACE] ?? [];

  if (!Array.isArray(roles)) {
    return userRoles.includes(roles);
  }
  return roles.some((role) => userRoles.includes(role));
};

const withClaimCheck = <P extends object>(
  Component: FunctionComponent<P>,
  restrictToRoles: RoleNameType | RoleNameType[],
) =>
  function WithClaimCheck(props: P) {
    const { user } = useAuth0<POPUser>();
    if (user && checkRoles(user, restrictToRoles)) {
      return <Component {...props} />;
    }
    return <AuthFailure />;
  };

interface Props<P = {}> {
  component: FunctionComponent<P>;
  restrictTo?: RoleNameType | RoleNameType[];
}

export default function AuthGuard({ component, restrictTo, ...props }: Props) {
  // the withAuthenticationRequired higher order component will show a login page
  // if they're not already logged in
  if (restrictTo) {
    const Component = withAuthenticationRequired(withClaimCheck(component, restrictTo), {
      onRedirecting,
    });
    return <Component {...props} />;
  }

  const Component = withAuthenticationRequired(component, {
    onRedirecting,
  });
  return <Component {...props} />;
}
