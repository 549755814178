import { useCallback } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import PostOpSetting from '../lib/postOpSetting';
import PreOpSetting from '../lib/preOpSetting';
import { InfectionControlSettings } from '../model/infectionControlSettings';

function useHQSettingsService() {
  const { get, put, loading } = useSecureAPI('hqsettings');

  const getPreOpSettings = useCallback(() => get<PreOpSetting>('preop'), [get]);

  const getPostOpSettings = useCallback(() => get<PostOpSetting>('postop'), [get]);

  const updatePreOpSettings = useCallback((data: PreOpSetting) => put('preop', data), [put]);

  const setAutoDispatch = useCallback(
    async (autoDispatch: boolean) => {
      const currentSettings = await getPreOpSettings();
      return updatePreOpSettings({ ...currentSettings, autoDispatch });
    },
    [getPreOpSettings, updatePreOpSettings],
  );

  const setAutoReuseRecentHq = useCallback(
    async (autoReuseRecentHq: boolean) => {
      const currentSettings = await getPreOpSettings();
      return updatePreOpSettings({ ...currentSettings, autoReuseRecentHq });
    },
    [getPreOpSettings, updatePreOpSettings],
  );

  const updatePostOpSettings = useCallback((data: PostOpSetting) => put('postop', data), [put]);

  const getLinkedProcedures = useCallback(() => get<number[]>('postop/linkedProcedures'), [get]);

  const updateLinkedProcedures = useCallback(
    (data: number[]) => put('postop/linkedProcedures', { procedureIds: data }),
    [put],
  );

  const getInfectionControlSettings = useCallback(
    () => get<InfectionControlSettings>('infectionControl'),
    [get],
  );

  const updateInfectionControlSettings = useCallback(
    (data: InfectionControlSettings) => put('infectionControl', data),
    [put],
  );

  const getRiskHospitals = useCallback(
    () => get<string[]>('infectionControl/riskHospitals'),
    [get],
  );

  const updateRiskHospitals = useCallback(
    (riskHospitals: string[]) => put('infectionControl/riskHospitals', { riskHospitals }),
    [put],
  );

  return {
    getPreOpSettings,
    getPostOpSettings,
    updatePreOpSettings,
    setAutoDispatch,
    setAutoReuseRecentHq,
    updatePostOpSettings,
    getLinkedProcedures,
    updateLinkedProcedures,
    getInfectionControlSettings,
    updateInfectionControlSettings,
    getRiskHospitals,
    updateRiskHospitals,
    loading,
  };
}

export default useHQSettingsService;
