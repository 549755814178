import { useCallback } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import { RoleNameType } from '../lib/types/roleName';
import User from '../lib/types/user';

export type UserUpdate = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roles: RoleNameType[];
};

type UserIdName = {
  name: string;
  firstName: string;
  lastName: string;
  id: number;
};

function useUserService() {
  const { get, put, post, deleteReq, loading } = useSecureAPI('users');
  const { put: init } = useSecureAPI('init');

  const getAllUsers = useCallback(() => get<User[]>(''), [get]);

  const getActivePractitioners = useCallback(() => get<User[]>('practitioners'), [get]);

  const updateUser = useCallback(
    (auth0Id: string, updateParams: Partial<UserUpdate>) =>
      put('updateUserInfo', { auth0Id, updateParams }),
    [put],
  );

  const inviteUser = useCallback((userInfo: UserUpdate) => post('', { userInfo }), [post]);

  const acceptInvite = useCallback(
    (auth0Id: string) => init<{ name: string }>('acceptInvite', { auth0Id }),
    [init],
  );

  const blockUser = useCallback((auth0Id: string) => put('blockUser', { auth0Id }), [put]);

  const unblockUser = useCallback((auth0Id: string) => put('unblockUser', { auth0Id }), [put]);

  const deleteInvite = useCallback(
    (inviteId: string) => deleteReq('deleteInvite', { inviteId }),
    [deleteReq],
  );

  const removeFromOrg = useCallback((auth0Id: string) => put('removeFromOrg', { auth0Id }), [put]);

  const getAllUserNames = useCallback(async () => get<UserIdName[]>('allUserNames'), [get]);

  return {
    getAllUsers,
    getActivePractitioners,
    updateUser,
    inviteUser,
    acceptInvite,
    blockUser,
    unblockUser,
    deleteInvite,
    removeFromOrg,
    getAllUserNames,
    loading,
  };
}

export default useUserService;
