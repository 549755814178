import { useAuth0 } from '@auth0/auth0-react';
import FullscreenErrorPage from '../components/fullscreenErrorPage';
import LoaderTextPage from '../components/loaderTextPage';
import { getAuth0Error } from './apiUtils';

function CallbackPage() {
  const { isLoading, isAuthenticated, error } = useAuth0();
  const errorCode = getAuth0Error(error);

  if (errorCode) {
    return (
      <FullscreenErrorPage
        error={errorCode}
        showButton={false}
        extraText="Please contact your administrator"
      />
    );
  }

  if (isAuthenticated && !isLoading) {
    return <FullscreenErrorPage error="Already authenticated" showButton />;
  }

  return <LoaderTextPage content="Authenticating" loading={isLoading} />;
}

export default CallbackPage;
