import { useCallback, useContext } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import { HqCollectionType } from '../lib/hqCollection';
import { BowelPrepInstruction, BowelPrepType, DietInstruction } from '../lib/types/bowelPrep';
import FieldUpdateDTO, { ProposedSurgeryField } from '../lib/types/DTO/fieldUpdateDTO';
import { IncompleteQuestionnaireKey } from '../lib/types/DTO/incompleteQuestionnaireKey';
import { FileBasicInfo } from '../lib/types/fileBasicInfo';
import Referral from '../lib/types/Referral';
import ReferralPrep from '../lib/types/referralPrep';
import { TimelineNote } from '../lib/types/timelineNote';
import { referralActionTypes, StoreContext } from '../store';

type AddConditionData = {
  questionData: Record<string, any>;
  conditionKey: string;
  noteId?: number;
};

type AddIssueData = {
  title: string;
  clinicalArea: string;
  noteId?: number;
};

type ReferralCondition = any;

export type RecentHQ = {
  questionnaireUuid: string;
  gpReferralId: number;
  createdAt: Date;
  collectionName: HqCollectionType;
};

export type ImportResults = {
  fileCount: number;
  totalRows: number;
  invalidRows: any[];
  newPatients: number;
  updatedPatients: number;
  newReferrals: number;
  updatedReferrals: number;
  removedReferrals: number;
  timeStart: number;
  timeFinish: number | null;
};

export type ReferralsWithRecentHqResponse = {
  /** Null if patient doesn't exist */
  patientUuid: null | string;
  recentReferrals: Referral[];
  recentHqs: RecentHQ[];
};

type SubmitReferralRequest = {
  fromOldReferral: boolean;
  oldQuestionnaireUuid: string | null;
  surgeonAuth0Id: string | null;
  anaesthetistAuth0Id: string | null;
  ur: string;
  patientTitle: string;
  patientFirstName: string;
  patientLastName: string;
  dateOfBirth: string;
  patientGender: string;
  patientEmailAddress: string;
  patientMobileNumber: string;
  kinName: string;
  kinEmailAddress: string;
  kinMobileNumber: string;
  kinCorrespondence: boolean;
  externalKey: null | string;
  triageCategory: null | string;
  sameDaySurgery: boolean;
  scheduledProcedureId: number;
  scheduledProcedureName: string;
  surgeryLocationId: number;
  surgicalUnitId: number | null;
  surgeryDate: string | null;
  gpName: string;
  gpEmailAddress: string;
  gpMobileNumber: string;
};

function useReferralService() {
  const { get, put, post, deleteReq, postFormData, loading } = useSecureAPI('gpreferrals');
  const [, dispatch] = useContext(StoreContext);

  const getReferralByUuid = useCallback(
    (referralUuid: string) => get<Referral>(`referralUuid/${referralUuid}`),
    [get],
  );

  const submitGpReferral = useCallback(
    (data: SubmitReferralRequest) => post<{ result: string | null }>('', data),
    [post],
  );

  const getReferralByUr = useCallback((ur: string) => get<Referral[]>(`patientUr/${ur}`), [get]);

  const getReferralsWithRecentHqByUr = useCallback(
    (ur: string) => get<ReferralsWithRecentHqResponse>(`recentHqByUr/${ur}`),
    [get],
  );

  const getReferralByExternalKey = useCallback(
    (externalKey: string) => get<Referral>(`externalKey/${externalKey}`),
    [get],
  );

  const getReferralPatientNotes = useCallback(
    (referralUuid: string) => get<TimelineNote[]>(`patientNotes/${referralUuid}`),
    [get],
  );

  const getReferralConditions = useCallback(
    (referralUuid: string) => get<ReferralCondition[]>(`${referralUuid}/conditions/`),
    [get],
  );

  const deleteReferrals = useCallback(
    (referralUuids: string[]) => deleteReq('', { referralUuids }),
    [deleteReq],
  );

  const importPatients = useCallback(
    (importCSV: any) => postFormData<ImportResults>('import', importCSV),
    [postFormData],
  );

  const getScheduledAdviceFiles = useCallback(
    (referralUuid: string) => get<FileBasicInfo[]>(`scheduledAdviceFiles/${referralUuid}`),
    [get],
  );

  const removeScheduledAdviceFile = useCallback(
    (removeFileUuid: string, referralUuid: string) =>
      put<void>(`scheduledAdviceFiles/${referralUuid}/remove`, { removeFileUuid }),
    [put],
  );

  const updateProposedSurgeryInfo = useCallback(
    (updateData: FieldUpdateDTO[], referralUuid: string) =>
      put<void>(`proposedSurgery/${referralUuid}`, { updateData }).then(() => {
        const surgeryUpdate = updateData.find((u) => u.field === ProposedSurgeryField.surgeryDate);
        if (surgeryUpdate) {
          dispatch({ type: referralActionTypes.SET_SURGERY_DATE, payload: surgeryUpdate.newValue });
        }

        const admissionTimeUpdate = updateData.find(
          (u) => u.field === ProposedSurgeryField.admissionTime,
        );
        if (admissionTimeUpdate) {
          dispatch({
            type: referralActionTypes.SET_ADMISSION_TIME,
            payload: admissionTimeUpdate.newValue,
          });
        }

        const procedureDisplayNameUpdate = updateData.find(
          (u) => u.field === ProposedSurgeryField.procedureDisplayName,
        );
        if (procedureDisplayNameUpdate) {
          dispatch({
            type: referralActionTypes.SET_PROCEDURE_DISPLAY_NAME,
            payload: procedureDisplayNameUpdate.newValue,
          });
        }
      }),
    [dispatch, put],
  );

  const setReferralAdvice = useCallback(
    (referralUuid: string, adviceDetails: string | null) =>
      put(`referralAdvice/${referralUuid}`, { adviceDetails }).then(() =>
        dispatch({ type: referralActionTypes.SET_ADVICE, payload: adviceDetails }),
      ),
    [dispatch, put],
  );

  const setAdviceScheduled = useCallback(
    (referralUuid: string, scheduled: boolean) =>
      post<void>(`setAdviceScheduled/${referralUuid}/`, { scheduled }),
    [post],
  );

  const skipScheduledAdvice = useCallback(
    (referralUuid: string) => put<void>(`skipScheduledAdvice/${referralUuid}`, {}),
    [put],
  );

  const sendScheduledAdvice = useCallback(
    (referralUuid: string) => put<void>(`sendScheduledAdvice/${referralUuid}`, {}),
    [put],
  );

  /** Add 'Note' or 'Custom condition' */
  const addIssue = useCallback(
    (updateData: AddIssueData, referralUuid: string) =>
      put<{ conditionId: number }>(`reviewInfo/${referralUuid}`, updateData),
    [put],
  );

  /** Add db defined issue / condition */
  const addCondition = useCallback(
    (updateData: AddConditionData, referralUuid: string) =>
      put<void>(`condition/${referralUuid}`, updateData),
    [put],
  );

  const updateCondition = useCallback(
    (updateData: AddConditionData, referralUuid: string) =>
      put<void>(`updateCondition/${referralUuid}`, updateData),
    [put],
  );

  const removeCondition = useCallback(
    (referralUuid: string, condition: number | string) =>
      typeof condition === 'number'
        ? deleteReq(`${referralUuid}/issue/${condition}`)
        : deleteReq(`${referralUuid}/condition/${condition}`),
    [deleteReq],
  );

  const restoreCondition = useCallback(
    (referralUuid: string, condition: string | number) =>
      typeof condition === 'number'
        ? put<void>(`${referralUuid}/issue/${condition}`, { restore: true })
        : put<void>(`${referralUuid}/condition/${condition}`, { restore: true }),
    [put],
  );

  const linkConditionNote = useCallback(
    (referralUuid: string, noteId: number, conditionKey: string) =>
      put<void>(`${referralUuid}/conditionNote/${conditionKey}`, { noteId }),
    [put],
  );

  const linkCustomConditionNote = useCallback(
    (referralUuid: string, noteId: number, conditionId: number) =>
      put<void>(`${referralUuid}/issueNote/${conditionId}`, { noteId }),
    [put],
  );

  const getIncompleteQuestionnaires = useCallback(
    (referralUuid: string) =>
      get<IncompleteQuestionnaireKey[]>(`questionnaires/incomplete/${referralUuid}`),
    [get],
  );

  const updateReferralPrep = useCallback(
    (
      referralUuid: string,
      bowelPrepType: BowelPrepType | null,
      dietInstructions: DietInstruction[],
      bowelPrepInstructions: BowelPrepInstruction[],
    ) =>
      post<void>(`${referralUuid}/referralPrep`, {
        prepType: bowelPrepType,
        dietInstructions,
        bowelPrepInstructions,
      }),
    [post],
  );

  const getReferralPrep = useCallback(
    (referralUuid: string) => get<ReferralPrep>(`${referralUuid}/referralPrep`),
    [get],
  );

  const getBowelPrepTranslationAvailable = useCallback(
    (referralUuid: string) =>
      get<{ translationAvailable: boolean }>(`${referralUuid}/bowelPrepTranslationAvailable`).then(
        (res) => res.translationAvailable,
      ),
    [get],
  );

  const updateListVisibility = useCallback(
    (referralUuid: string, visibleToEndoscopyAndGeneral: boolean) =>
      put<void>(`${referralUuid}/visibleToEndoscopyAndGeneral`, { visibleToEndoscopyAndGeneral }),
    [put],
  );

  return {
    getReferralByUuid,
    submitGpReferral,
    getReferralByUr,
    getReferralsWithRecentHqByUr,
    getReferralByExternalKey,
    getReferralPatientNotes,
    getReferralConditions,
    deleteReferrals,
    importPatients,
    getScheduledAdviceFiles,
    removeScheduledAdviceFile,
    updateProposedSurgeryInfo,
    setReferralAdvice,
    skipScheduledAdvice,
    sendScheduledAdvice,
    setAdviceScheduled,
    addIssue,
    updateCondition,
    addCondition,
    removeCondition,
    restoreCondition,
    linkConditionNote,
    linkCustomConditionNote,
    getIncompleteQuestionnaires,
    updateListVisibility,
    loading,
    updateReferralPrep,
    getReferralPrep,
    getBowelPrepTranslationAvailable,
  };
}

export default useReferralService;
