export enum TestStatus {
  None = 'none',
  Updated = 'updated',
  Ordered = 'ordered',
  Received = 'received',
}

export const testStatusNames: Record<TestStatus, string> = {
  none: 'None added',
  updated: 'Not ordered',
  ordered: 'Ordered',
  received: 'Reviewed',
} as const;
