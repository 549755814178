import { AsType } from '../../lib/tsUtils';

/**
 * Errors that require handling in a specific way
 */
export const ErrorName = {
  /** Should be ignored - User will be redirected to login */
  REQUIRES_LOGIN: 'REQUIRES_LOGIN',
} as const;

type ErrorNameType = AsType<typeof ErrorName>;

type AppErrDetails = {
  title: string;
  detail: string;
  status?: number;
  errName?: ErrorNameType;
};

class AppError extends Error {
  readonly title: string;

  readonly detail: string;

  readonly status?: number;

  readonly errName?: ErrorNameType;

  readonly initError: Error | null;

  constructor({ title, detail, status, errName }: AppErrDetails, initError: Error | null = null) {
    super(title);
    this.name = 'AppError';

    this.title = title;
    this.detail = detail;
    this.status = status;
    this.errName = errName;
    this.initError = initError;
  }
}

export const PERMISSION_ERROR = {
  title: 'Permission error',
  detail: 'You do not have permission for this action.',
  status: 401,
  code: 401,
};

/** End Error handling for class components */

type SetErrorStatusFn = (error: any) => void;

/**
 * Error handling for functional componenets.
 *
 * Usage: set a hook variable to error when it happens, then throw exception
 * during render, which will be picked up by error boundary
 *
 * To use, create a useState hook for the error status in the functional
 * component's constructor, e.g.
 *   const [errorStatus, setErrorStatus] = useState(null);
 *
 * Where error catches occur, call processErrorResponseInFunctional, to extract
 * error details and store in state hook
 * ```
 *   .catch((error) => {
 *         processErrorResponseInFunctional(error, setErrorStatus);
 *       });
 * ```
 * Before the functional component returns, call handleErrorInFunctional to
 * raise an exception at runtime and trigger ErrorBoundary
 *    `handleErrorInFunctional(errorStatus, setErrorStatus);`
 */
const processErrorResponseInFunctional = async (
  err: any,
  setErrorStatus: SetErrorStatusFn,
  signal?: AbortSignal,
) => {
  if (signal?.aborted) {
    // If we have an AbortSignal passed in we can check if the error comes from a fetch abort
    return;
  }

  if (!err) {
    return;
  }

  if (err.name === 'AbortError') {
    // Indicates a request has been aborted (through unmounting or timeout)
    return;
  }

  if (err instanceof Response) {
    // eslint-disable-next-line no-param-reassign
    err = await err.json();
  }

  setErrorStatus(err);
};

const handleErrorInFunctional = (err: any, setErrorStatus: SetErrorStatusFn) => {
  if (!err) {
    return;
  }

  // Clear error
  setErrorStatus(null);

  // Bubble up to ErrorBoundary
  if (err instanceof AppError) {
    throw err;
  }

  // Error was not an AppError, wrap it in one
  throw new AppError(
    {
      title: 'API Response Error',
      detail: err.message ?? '',
    },
    err,
  );
};

/** End Error handling for functional component */

export { AppError, handleErrorInFunctional, processErrorResponseInFunctional };
