import { SvgIcon } from '@material-ui/core';

/**
 * @param {SvgIconProps} props
 */
function CommsSuccessIcon(props) {
  return (
    <SvgIcon {...props}>
      <mask
        id="mask0_2074_36"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2074_36)">
        <path
          d="M15.95 22L11.7 17.75L13.1 16.35L15.95 19.2L21.6 13.55L23 14.95L15.95 22ZM12 11L20 6H4L12 11ZM12 13L4 8V18H9.15L11.15 20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V10.35L20 12.35V8L12 13Z"
          fill="inherit"
        />
      </g>
    </SvgIcon>
  );
}

export default CommsSuccessIcon;
