import { makeStyles, Popover, Typography } from '@material-ui/core';
import { Error, Info, Warning } from '@material-ui/icons';
import clsx from 'clsx';
import { ComponentChildren } from 'preact';
import { useState } from 'preact/hooks';
import { RiskLevelFilterText } from '../../../lib/riskDisplayUtils';
import { PartialRecord } from '../../../lib/tsUtils';
import RiskLevel, { RiskLevelType } from '../../../lib/types/riskLevel';
import sharedConstants from '../../../shared-constants';

const useStyles = makeStyles((theme) => ({
  /** Base class */
  statusHeading: {
    minHeight: 34,
    padding: '6px 8px',
    fontWeight: 800,
    borderRadius: '3px',
    color: 'white',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  recommendationIcon: {
    display: 'inline',
    position: 'relative',
    marginLeft: 4,
  },
  recommendationList: {
    margin: 8,
    paddingLeft: 24,
  },
  riskHigh: {
    backgroundColor: sharedConstants.trafficLight.red,
  },
  riskMed: {
    backgroundColor: sharedConstants.trafficLight.orange,
  },
  riskLow: {
    backgroundColor: sharedConstants.trafficLight.green,
  },
  popover: {
    pointerEvents: 'none',
  },
  limitWidth: {
    maxWidth: 440,
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

type RiskBadgeProps = {
  riskLevel: RiskLevelType | null;
  popoverContent?: ComponentChildren;
  assessment?: string | null;
  suggestion?: string | null;
  suggestionTitle?: string | null;
  textMapping?: PartialRecord<RiskLevelType, string>;
};

/**
 * Badge that displays risk score
 * Recommendations are displayed on hover
 */
function RiskBadge({
  riskLevel,
  suggestion,
  assessment,
  popoverContent,
  suggestionTitle = 'Recommendation',
  textMapping = RiskLevelFilterText,
}: RiskBadgeProps) {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  if (!riskLevel) {
    return null;
  }

  const recommendation = suggestion ?? null;

  const classname = clsx(
    classes.statusHeading,
    riskLevel === RiskLevel.High && classes.riskHigh,
    riskLevel === RiskLevel.Medium && classes.riskMed,
    riskLevel === RiskLevel.Low && classes.riskLow,
  );

  const open = Boolean(anchorEl);

  const getRecommendationList = () => {
    if (!recommendation) {
      return [];
    }
    return recommendation.includes('/') ? recommendation.split('/') : [recommendation];
  };

  return (
    <>
      <span
        className={classname}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <span>{textMapping[riskLevel]}</span>
        {(recommendation || popoverContent) && (
          <>
            {riskLevel === RiskLevel.Low && (
              <Info className={classes.recommendationIcon} fontSize="small" />
            )}
            {riskLevel === RiskLevel.Medium && (
              <Warning className={classes.recommendationIcon} fontSize="small" />
            )}
            {riskLevel === RiskLevel.High && (
              <Error className={classes.recommendationIcon} fontSize="small" />
            )}
          </>
        )}
      </span>

      {(recommendation || popoverContent) && (
        <Popover
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          disableRestoreFocus
        >
          <div className={classes.limitWidth}>
            {recommendation && (
              <>
                {suggestionTitle && <Typography variant="h4">{suggestionTitle}</Typography>}
                <ul className={classes.recommendationList}>
                  {getRecommendationList().map((r) => (
                    <li key={r}>{r}</li>
                  ))}
                </ul>
              </>
            )}

            {assessment && (
              <>
                <Typography variant="h4">Assessment</Typography>
                <ul className={classes.recommendationList}>
                  {assessment.split('/').map((r) => (
                    <li key={r}>{r}</li>
                  ))}
                </ul>
              </>
            )}

            {popoverContent}
          </div>
        </Popover>
      )}
    </>
  );
}

export default RiskBadge;
