import { AsType } from '../tsUtils';

const RoleName = {
  Nurse: 'nurse',
  Admin: 'admin',
  Surgeon: 'surgeon',
  Anaesthetist: 'anaesthetist',
  SuperAdmin: 'superAdmin',
} as const;

export const RoleClinician = [RoleName.Nurse, RoleName.Surgeon, RoleName.Anaesthetist];

export type RoleNameType = AsType<typeof RoleName>;
export default RoleName;
