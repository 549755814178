import { useContext } from 'react';
import { StoreContext } from '../store';

function useEnglishLocale() {
  const [state] = useContext(StoreContext);
  const { localeCode } = state;
  return localeCode === 'en';
}

export default useEnglishLocale;
