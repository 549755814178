import { useEffect, useMemo, useState } from 'react';

function useVoiceToText() {
  const [transcript, setTranscript] = useState('');
  const [isListening, setIsListening] = useState(false);
  const [microphonePermissionState, setMicrophonePermissionState] = useState<
    PermissionState | 'notAvailable'
  >('prompt');
  const [browserSettingsInstruction, setBrowserSettingsInstruction] = useState('');

  const { SpeechRecognition, webkitSpeechRecognition } = window as any; // Typescript issue, doesn't know window.SpeechRecognition exists

  const recognition = useMemo(() => {
    if (SpeechRecognition || webkitSpeechRecognition) {
      const RecogType = SpeechRecognition || webkitSpeechRecognition;
      return new RecogType();
    }
    return null;
  }, [SpeechRecognition, webkitSpeechRecognition]);

  useEffect(() => {
    if (recognition) {
      recognition.continuous = true;
      recognition.interimResults = true;
    }
  }, [recognition]);

  const clearTranscript = async () => {
    if (recognition) {
      await recognition.abort();

      const originalOnEnd = recognition.onend;

      recognition.onend = () => {
        if (isListening) {
          setTimeout(() => {
            recognition.start();
            setTranscript('');
            if (originalOnEnd) {
              originalOnEnd();
            }
          }, 100);
        }
        recognition.onend = originalOnEnd;
      };
    }
  };

  useEffect(() => {
    const ua = navigator.userAgent;
    if (/Edg/i.test(ua)) {
      setBrowserSettingsInstruction(
        'Go to the three dots on the top right → Settings → Cookies and Site Permissions → Microphone.',
      );
      return;
    }
    if (/chrome/i.test(ua) && !/edg/i.test(ua)) {
      setBrowserSettingsInstruction(
        'Go to the three dots on the top right → Settings → Privacy and security → Site settings → Microphone.',
      );
      return;
    }
    if (/firefox/i.test(ua)) {
      setBrowserSettingsInstruction(
        'Click the three lines on the top right → Options → Privacy & Security → Permissions → Microphone Settings.',
      );
      return;
    }
    if (/safari/i.test(ua) && !/chrome/i.test(ua)) {
      setBrowserSettingsInstruction('Go to Safari → Preferences → Websites → Microphone.');
    }
  }, []);

  useEffect(() => {
    // Brave does not support voice recognition
    const isBrave = (navigator as any).brave || false;

    if (!isBrave && 'permissions' in navigator) {
      navigator.permissions
        .query({ name: 'microphone' as any }) // Issue with typescript, doesn't know microphone exists
        .then((result) => {
          if (['granted', 'denied', 'prompt'].includes(result.state)) {
            setMicrophonePermissionState(result.state);
          }

          // Listen for changes in the permission status
          // eslint-disable-next-line no-param-reassign
          result.onchange = () => {
            setMicrophonePermissionState(result.state);
          };
        })
        .catch(() => {
          setMicrophonePermissionState('notAvailable');
        });
    } else {
      setMicrophonePermissionState('notAvailable');
    }
  }, [isListening]);

  useEffect(() => {
    if (recognition) {
      recognition.onresult = (event: { results: any }) => {
        const transcriptArr = [...event.results].map((result) => result[0].transcript);
        setTranscript(transcriptArr.join(' '));
      };

      if (isListening) {
        recognition.start();
      } else {
        recognition.abort();
      }

      recognition.onerror = (event: { error: string }) => {
        if (event.error === 'not-allowed') {
          setMicrophonePermissionState('denied');
        }
      };
    }

    return () => {
      recognition?.abort();
    };
  }, [isListening, recognition]);

  return {
    isListening,
    setIsListening,
    transcript,
    clearTranscript,
    microphonePermissionState,
    browserSettingsInstruction,
  };
}

export default useVoiceToText;
