import { useCallback } from 'react';
import useInsecureAPI from '../auth/useInsecureAPI';
import useSecureAPI from '../auth/useSecureAPI';
import FeatureSwitch from '../lib/featureSwitch';
import Feature from '../lib/types/feature';

type FeatureSwitchRequest = {
  featureName: string;
  enabled: boolean;
};

/**
 * Service to handle toggling features, and getting status of all features
 * requires superadmin role
 */
function useFeatureSwitchService() {
  const { get: insecureGet } = useInsecureAPI('featureSwitch');

  const getEnabledFeatures = useCallback(
    () => insecureGet<Feature[]>('enabledFeatures'),
    [insecureGet],
  );

  const { get, put, loading } = useSecureAPI('featureSwitch');

  const getAllFeatureSwitchSettings = useCallback(() => get<FeatureSwitch[]>(''), [get]);
  const enableFeatureSwitchSetting = useCallback(
    (req: FeatureSwitchRequest) => put('', [req]),
    [put],
  );

  return {
    getEnabledFeatures,
    getAllFeatureSwitchSettings,
    enableFeatureSwitchSetting,
    loading,
  };
}

export default useFeatureSwitchService;
