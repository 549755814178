import { AsType } from '../tsUtils';

const RiskLevel = {
  Low: 'low',
  Medium: 'med',
  High: 'high',
} as const;

export type RiskLevelType = AsType<typeof RiskLevel>;
export default RiskLevel;
