import { Badge, ButtonBase, ButtonBaseProps, makeStyles, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  headerLink: {
    height: '62px',
    top: '19px',
    position: 'relative',
    borderBottom: '4px solid',
    margin: '0 14px',
  },
  buttonBase: {
    borderRadius: '4px',
  },
  active: {
    color: theme.palette.secondary.main,
  },
  inactive: {
    color: '#707070',
    borderBottom: '4px solid transparent',
  },
  badge: {
    '& > *': {
      right: theme.spacing(-2.5),
    },
  },
}));

type HeaderLinkProps = {
  title: string;
  link: string;
  active: boolean;
  badgeText?: string;
} & ButtonBaseProps;

function HeaderLink({ title, link, active = false, badgeText, ...props }: HeaderLinkProps) {
  const navigate = useNavigate();
  const classes = useStyles();
  const className = `${classes.headerLink} ${active ? classes.active : classes.inactive}`;

  return (
    <ButtonBase
      onClick={() => navigate(link)}
      className={classes.buttonBase}
      title={title}
      {...props}
    >
      <Typography variant="subtitle1" className={className}>
        <Badge badgeContent={badgeText} color="secondary" className={classes.badge}>
          {title}
        </Badge>
      </Typography>
    </ButtonBase>
  );
}

export default HeaderLink;
