/* eslint-disable react/sort-comp */
/* eslint-disable no-console */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Component, ComponentChildren, ErrorInfo } from 'preact';
import { isDevelopment } from '../../lib/utils';
import awsRum from '../awsRum';
import { AppError } from './errorHandlingUtils';

const supportMessage = 'Please try again or contact customer support if this issue persists.';

const defaultTitle = 'Something went wrong';
const defaultDetail = 'An unexpected error occurred.';

type Props = {
  children: ComponentChildren;
};

type State = {
  error: Error | null;
  errorDialogOpen: boolean;
  errorDetails: any;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      errorDialogOpen: false,
      error: null,
      errorDetails: null,
    };
  }

  static shouldIgnoreError(error: AppError | Error) {
    if (error instanceof AppError) {
      return error.errName === 'REQUIRES_LOGIN';
    }
    return false;
  }

  static getDerivedStateFromError(error: any) {
    if (isDevelopment) {
      console.error(error);
    }

    if (this.shouldIgnoreError(error)) {
      return {
        error: null,
        errorDialogOpen: false,
        errorDetails: null,
      };
    }

    return {
      error,
      errorDialogOpen: true,
      errorDetails: error.details,
    };
  }

  componentDidCatch(error: any, info: ErrorInfo) {
    if (isDevelopment) {
      console.error(error);
      console.error(info);
    }
    awsRum.recordError(error);
  }

  closeErrorDialog = () => {
    this.setState({
      errorDialogOpen: false,
    });
  };

  getErrorDetails = () => {
    const { error, errorDetails } = this.state;

    if (error instanceof AppError) {
      // extract message (title) and details
      return [
        {
          title: error.title || defaultTitle,
          detail: error.detail || defaultDetail,
        },
      ];
    }

    if (errorDetails && typeof errorDetails === 'object' && 'errors' in errorDetails) {
      return errorDetails.errors;
    }

    // Attempt to handle string error_details
    if (typeof errorDetails === 'string') {
      return [
        {
          title: defaultTitle,
          detail: errorDetails || defaultDetail,
        },
      ];
    }

    if (errorDetails && typeof errorDetails === 'object') {
      const msg = errorDetails?.message;
      if (msg != null) {
        return [
          {
            title: defaultTitle,
            detail: msg || defaultDetail,
          },
        ];
      }
    }

    return [
      {
        title: defaultTitle,
        detail: defaultDetail,
      },
    ];
  };

  renderErrorMessage = () => {
    const response: JSX.Element[] = [];
    const errorDetails = this.getErrorDetails();

    if (errorDetails && Array.isArray(errorDetails)) {
      const errorList = errorDetails.map((err) => (
        <p>
          <b>{err.title}</b>
          {err.detail ? ` - ${err.detail}` : null}
        </p>
      ));

      response.push(...errorList);
    }

    response.push(<p>{supportMessage}</p>);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{response}</>;
  };

  render() {
    const { children } = this.props;
    const { errorDialogOpen } = this.state;

    return (
      <div>
        {children}
        <Dialog
          open={errorDialogOpen}
          onClose={this.closeErrorDialog}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Could not complete your request</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.renderErrorMessage()}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeErrorDialog} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ErrorBoundary;
