import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Control, Controller } from 'react-hook-form';
import useStyles from './styles';

type InfoFormProps = {
  control: Control<Record<string, any>>;
  handleSubmit: any;
  watch: (field: string) => boolean;
  clearErrors: () => void;
  errors: any;
  patientName: string;
  logoUrl: string | null;
};

function InfoForm({
  control,
  handleSubmit,
  watch,
  clearErrors,
  errors,
  patientName,
  logoUrl,
}: InfoFormProps) {
  const classes = useStyles();

  const sendToNok = watch('sendToNok');
  const phoneOnly = watch('phoneOnly');

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" justifyContent="center" spacing={0}>
          <Grid item sm={12} md={6}>
            <Paper className={classes.paper}>
              {logoUrl && (
                <div>
                  <div className={classes.logo}>
                    <img src={logoUrl} alt="logo" className={classes.img} />
                  </div>
                  <Typography variant="h3" style={{ lineHeight: '2.5' }}>
                    Patient info form
                  </Typography>
                </div>
              )}

              <Typography>
                Hello {patientName}, please confirm your details so we can contact you about your
                placement on the surgical waitlist.
              </Typography>

              <Box sx={{ marginTop: 4, fontStyle: 'italic' }}>
                (Not you? Please ignore this form)
              </Box>

              <div>
                <Controller
                  name="email"
                  control={control}
                  render={({ onChange, value }) => (
                    <TextField
                      label="Email"
                      error={!!errors?.email}
                      value={value}
                      onChange={onChange}
                      helperText={
                        !!errors?.email && 'Please ensure email address is entered correctly'
                      }
                      className={classes.fieldItem}
                      disabled={phoneOnly}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="sendToNok"
                  control={control}
                  render={({ onChange, value }) => (
                    <FormControl error={!!errors?.terms} style={{ width: '100%' }}>
                      <FormControlLabel
                        label="Send to next of kin"
                        control={
                          <Checkbox
                            onChange={(e: any) => {
                              onChange(e.target.checked);
                            }}
                            checked={value}
                          />
                        }
                      />
                    </FormControl>
                  )}
                />
              </div>

              {sendToNok && (
                <>
                  <Typography variant="h4" className={classes.fieldItem}>
                    Next of kin details
                  </Typography>

                  <div>
                    <Controller
                      name="nokFirstName"
                      control={control}
                      render={({ onChange, value }) => (
                        <TextField
                          label="First name"
                          error={!!errors?.nokFirstName}
                          value={value}
                          onChange={onChange}
                          helperText={
                            !!errors?.nokFirstName && "Please provide your NOK's first name"
                          }
                          className={classes.fieldItem}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>

                  <div>
                    <Controller
                      name="nokLastName"
                      control={control}
                      render={({ onChange, value }) => (
                        <TextField
                          label="Last name"
                          error={!!errors?.nokLastName}
                          value={value}
                          onChange={onChange}
                          helperText={
                            !!errors?.nokLastName && "Please provide your NOK's last name"
                          }
                          className={classes.fieldItem}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>

                  <div>
                    <Controller
                      name="nokEmail"
                      control={control}
                      render={({ onChange, value }) => (
                        <TextField
                          label="Email"
                          error={!!errors?.nokEmail}
                          value={value}
                          onChange={onChange}
                          helperText={!!errors?.nokEmail && 'Please provide a valid email address'}
                          className={classes.fieldItem}
                        />
                      )}
                      rules={{ required: true }}
                    />
                  </div>

                  <div>
                    <Controller
                      name="kinMobileNumber"
                      control={control}
                      render={({ onChange, value }) => (
                        <TextField
                          label="Mobile number (optional)"
                          value={value}
                          onChange={onChange}
                          className={classes.fieldItem}
                        />
                      )}
                    />
                  </div>
                </>
              )}

              <div className={classes.fieldItem}>
                Don&apos;t have an email address? Please check the box below.
                <Controller
                  name="phoneOnly"
                  control={control}
                  render={({ onChange, value }) => (
                    <FormControl error={!!errors?.terms} style={{ width: '100%' }}>
                      <FormControlLabel
                        label="I do not have an email address"
                        control={
                          <Checkbox
                            onChange={(e: any) => {
                              onChange(e.target.checked);
                              // Clear existing 'missing email' error
                              clearErrors();
                            }}
                            checked={value}
                          />
                        }
                      />
                    </FormControl>
                  )}
                />
              </div>

              <Box marginTop="1rem" display="flex" justifyContent="flex-end">
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

export default InfoForm;
