import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Helmet from 'react-helmet';
import { useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginTop: 75,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

function GpReferralSuccess() {
  const { hqKey, optOut } = useParams();
  const classes = useStyles();

  return (
    <div className={classes.layout}>
      <Helmet title="New Referral" />
      <Paper className={classes.paper}>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item>
            <Alert variant="filled" severity="success">
              The new referral has been successfully submitted.
              {optOut === 'true'
                ? ' The patient has opted out of receiving emails, and will not receive a health questionnaire email.'
                : ' An email will be sent to the patient (or their next of kin) to prompt them to complete the health questionnaire.'}
              <br />
              <br />
              The health questionnaire for this patient can be accessed directly{' '}
              <Link
                component={RouterLink}
                to={`/healthquestionnaire/${hqKey}`}
                color="textSecondary"
                underline="always"
              >
                here
              </Link>
              .
            </Alert>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default GpReferralSuccess;
