import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { StateUpdater, useEffect, useState } from 'preact/hooks';
import { processErrorResponseInFunctional } from '../../../components/errorBoundary/errorHandlingUtils';
import { useDispatchAlert } from '../../../hooks';
import { ReferralListItem } from '../../../lib/types/referralListItem';
import { ReferralStatus, referralStatusNames } from '../../../lib/types/referralStatus';
import useHQReviewService from '../../../services/useHQReviewService';

type SendHQDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedReferrals: ReferralListItem[];
  onError: StateUpdater<any>;
  onUpdate?: () => void;
};

function SendHQDialog({
  open,
  onClose,
  selectedReferrals,
  onError,
  onUpdate = () => null,
}: SendHQDialogProps) {
  const dispatchAlert = useDispatchAlert();
  const hqReviewService = useHQReviewService();
  const [invalidReferrals, setInvalidReferrals] = useState<ReferralListItem[]>([]);
  const [skippedReferrals, setSkippedReferrals] = useState<ReferralListItem[]>([]);
  const [sendingComplete, setSendingComplete] = useState(false);

  useEffect(() => {
    setInvalidReferrals(selectedReferrals.filter(({ patient }) => !!patient.email));
    setSkippedReferrals(
      selectedReferrals.filter(({ currentState: state }) => state !== ReferralStatus.WaitingList),
    );
  }, [selectedReferrals]);

  const handleClose = () => {
    onClose();
    setInvalidReferrals([]);
    setSendingComplete(false);
  };

  const handleComplete = () => {
    handleClose();
    onUpdate();
  };

  const handleSendQuestionnaire = () => {
    const sendReferrals = selectedReferrals
      .filter(({ currentState: state }) => state === ReferralStatus.WaitingList)
      .filter(({ patient }) => !!patient.email)
      .map((ref) => ref.gpReferralUuid);

    hqReviewService
      .createAllQuestionnaires(sendReferrals)
      .then(() => dispatchAlert(`Sent ${sendReferrals.length} questionnaires`, false))
      .catch((e) => processErrorResponseInFunctional(e, onError))
      .finally(() => {
        setInvalidReferrals([]);
        setSendingComplete(true);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="confirm-send-questionnaire"
    >
      <DialogTitle id="confirm-send-questionnaire-title">Send questionnaire</DialogTitle>
      <DialogContent style={{ fontSize: '1rem' }}>
        {sendingComplete ? (
          <DialogContentText>
            <Typography>Health questionnaires sent</Typography>
          </DialogContentText>
        ) : (
          <>
            <DialogContentText>
              <Typography>
                This will send a questionnaire to the selected patients in status&nbsp;
                {referralStatusNames.waitingList}
              </Typography>
            </DialogContentText>

            {skippedReferrals.length + invalidReferrals.length > 0 && (
              <Typography variant="subtitle1" color="error">
                Unable to send referral to all recipients
              </Typography>
            )}

            {invalidReferrals.length > 0 && (
              <>
                <Typography variant="subtitle2">
                  The following patients have a missing or invalid email address
                </Typography>
                <List>
                  {invalidReferrals.map(({ patient }) => {
                    const { firstName, lastName, patientUR } = patient;
                    return (
                      <ListItem key={patientUR}>
                        <ListItemText primary={`${firstName} ${lastName} (${patientUR})`} />
                      </ListItem>
                    );
                  })}
                </List>
              </>
            )}
            {skippedReferrals.length > 0 && (
              <>
                <Typography variant="subtitle2">
                  The following patients have already been sent a questionnaire
                </Typography>
                <List>
                  {skippedReferrals.map(({ patient }) => {
                    const { firstName, lastName, patientUR } = patient;
                    return (
                      <ListItem key={patientUR}>
                        <ListItemText primary={`${firstName} ${lastName} (${patientUR})`} />
                      </ListItem>
                    );
                  })}
                </List>
              </>
            )}
            {skippedReferrals.length + invalidReferrals.length > 0 && (
              <Typography variant="body2">
                Press Send Questionnaire to send to remaining patients
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {sendingComplete ? (
          <Button color="primary" onClick={handleComplete}>
            Close
          </Button>
        ) : (
          <>
            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleSendQuestionnaire}
              disabled={invalidReferrals.length === selectedReferrals.length}
            >
              Send Questionnaire
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default SendHQDialog;
