import { useCallback } from 'preact/hooks';
import Feature from '../lib/types/feature';
import useFeatureSwitchService from '../services/useFeatureSwitchService';

function useCheckFeatures(features: Feature[] | Feature) {
  const { getEnabledFeatures } = useFeatureSwitchService();

  const checkFeatures = useCallback(
    () =>
      getEnabledFeatures().then((featureList) => {
        const featureListArray = Array.isArray(features) ? features : [features];
        return featureListArray.every((feature) => featureList.includes(feature));
      }),
    [],
  );
  return checkFeatures;
}

export default useCheckFeatures;
