import { Checkbox, ListItemText, MenuItem, Select, Typography } from '@material-ui/core';
import { CSSProperties } from 'preact/compat';
import { selectMenuProps } from '../../../lib/muiUtils';
import { ReferralStatus, referralStatusNames } from '../../../lib/types/referralStatus';
import { ColumnId } from '../referralTableColumn';
import { getTabOptions, TabOptionKey } from '../tabSelector';
import useActiveReferralListType from '../useActiveReferralListType';

// Needs a slight adjustment to keep in line with other filters
const selectStyle: CSSProperties = {
  marginTop: '4px',
};

type StatusFilterProps = {
  cellId: ColumnId;
  selectedFilters: ReferralStatus[];
  onFilterChange: (selectedStates: ReferralStatus[]) => void;
  currentTab: TabOptionKey;
  filterVisible: (filterKey: ReferralStatus) => boolean;
};

function StatusFilter({
  cellId,
  selectedFilters,
  onFilterChange,
  currentTab,
  filterVisible,
}: StatusFilterProps) {
  const listType = useActiveReferralListType();

  // Get valid status options for selected tab
  const tabOptions = getTabOptions(listType);
  const statusOptions = tabOptions[currentTab].filter.workflow?.filter(filterVisible) ?? [];

  return (
    <Select
      name={`${cellId}Filter`}
      multiple
      fullWidth
      style={selectStyle}
      margin="dense"
      variant="outlined"
      value={selectedFilters}
      renderValue={(selected) => (
        <Typography noWrap>
          {(selected as ReferralStatus[]).map((s) => referralStatusNames[s]).join(', ')}
        </Typography>
      )}
      MenuProps={selectMenuProps}
      onChange={(e) => {
        const states = (e.target as HTMLInputElement).value as unknown as ReferralStatus[];
        onFilterChange(states);
      }}
    >
      {statusOptions?.map((status) => (
        <MenuItem key={status} value={status}>
          <Checkbox checked={selectedFilters.indexOf(status) > -1} />
          <ListItemText primary={referralStatusNames[status]} />
        </MenuItem>
      ))}
    </Select>
  );
}

export default StatusFilter;
