import { useCallback, useEffect, useState } from 'preact/hooks';
import Feature from '../lib/types/feature';
import Risk, { RiskType } from '../lib/types/riskType';
import useFeatureSwitchService from '../services/useFeatureSwitchService';

// custom hook to get features and flags relaed to feature switches
function useFeatures() {
  const { getEnabledFeatures, loading: featuresLoading } = useFeatureSwitchService();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [featureList, setFeatureList] = useState<Feature[]>([]);
  const [displayDASI, setDisplayDASI] = useState(false);

  const loading = isLoading || featuresLoading;

  useEffect(() => {
    getEnabledFeatures()
      .then(setFeatureList)
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [getEnabledFeatures]);

  useEffect(() => {
    setDisplayDASI(featureList.includes(Feature.DASI));
  }, [featureList]);

  // Filter for list of ReferralRisks, to determine whether to show DASI or MDASI
  const filterDASI = useCallback(
    ({ riskType }: { riskType: RiskType }) => {
      if (riskType === Risk.MDASI) {
        return !displayDASI;
      }
      // Include DASI if feature switch for DASI is enabled
      return riskType === Risk.DASI ? displayDASI : true;
    },
    [displayDASI],
  );
  return { error, loading, featureList, filterDASI };
}

export default useFeatures;
