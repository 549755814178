import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  avatar: {
    height: 62,
    width: 62,
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 7,
    paddingBottom: 7,
  },
  nameContainer: {
    paddingLeft: 7,
    fontSize: 16,
    width: '100%',
  },
}));

export default useStyles;
