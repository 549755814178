import { useContext } from 'react';
import { referralActionTypes, StoreContext } from '../../store';
import { TabOptionKey } from './tabSelector';
import { FilterParams, RibbonFilterMap } from './types';

function useDispatchFilter() {
  const [, dispatch] = useContext(StoreContext);

  const dispatchTab = (tab: TabOptionKey) =>
    dispatch({
      type: referralActionTypes.SET_REFERRAL_LIST_TAB,
      payload: tab,
    });

  const dispatchRibbonFilters = (filters: RibbonFilterMap) =>
    dispatch({
      type: referralActionTypes.SET_REFERRAL_LIST_RIBBON_FILTERS,
      payload: filters,
    });

  const dispatchReferralListFilters = (filters: Partial<FilterParams>) =>
    dispatch({
      type: referralActionTypes.SET_REFERRAL_LIST_FILTERS,
      payload: filters,
    });

  const dispatchPredictionListFilters = (filters: FilterParams) =>
    dispatch({
      type: referralActionTypes.SET_REFERRAL_PREDICTION_LIST_FILTERS,
      payload: filters,
    });

  return {
    dispatchTab,
    dispatchRibbonFilters,
    dispatchReferralListFilters,
    dispatchPredictionListFilters,
  };
}

export default useDispatchFilter;
