import { useCallback } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import { FilterOptions, SortOption } from '../routes/referralList/types';

type ExtractParamsResponse = {
  response: {
    sortOptions: Partial<SortOption>;
    apiParameters: Partial<FilterOptions>;
  } | null;
};

function useNaturalLanguageService() {
  const { post, loading } = useSecureAPI('naturalLanguage');

  const getCompletion = useCallback(
    (query: string) =>
      post<ExtractParamsResponse>('extractParams', { userRequest: query }).then((r) => r.response),
    [post],
  );

  return {
    getCompletion,
    loading,
  };
}

export default useNaturalLanguageService;
