/*
  In future this can be extended to allow asking and answering multiple questions.
*/
enum PatientQuestionStatus {
  None = 'none',

  /** Patient has unanswered question(s)  */
  Asked = 'asked',

  /** All questions answered */
  Answered = 'answered',
}

export default PatientQuestionStatus;
