import { useCallback } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import PatientAdviceMode from '../routes/patientAdvicePreview/patientAdviceMode';

type EmailTemplateType = 'admissionLetter';

function useCommsService() {
  const { get, loading } = useSecureAPI('comms');

  const getSmsListByGpReferralId = useCallback(
    (referralUuid: string): Promise<any[]> => get(`sms/${referralUuid}`),
    [get],
  );

  const getEmailTemplate = useCallback(
    (referralUuid: string, templateName: EmailTemplateType) =>
      get<{ html: string }>(`${referralUuid}/template/${templateName}`).then(
        (response) => response.html,
      ),
    [get],
  );

  const checkValidTemplate = useCallback(
    (adviceMode: PatientAdviceMode) => get<boolean>(`template/${adviceMode}`),
    [get],
  );

  return {
    getSmsListByGpReferralId,
    getEmailTemplate,
    checkValidTemplate,
    loading,
  };
}

export default useCommsService;
