import { IconButton, MenuItem } from '@material-ui/core';
import { ViewList } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import useStyles from '../settingDialogStyle';

interface SuperSettingsMenuProps {
  onItemSelect: () => void;
}

function SuperSettingsMenu({ onItemSelect }: SuperSettingsMenuProps): JSX.Element {
  const navigate = useNavigate();
  const classes = useStyles();

  const makeLinkHandler = (linkRoute: string) => () => {
    onItemSelect();
    return navigate(linkRoute);
  };

  const handleFeatureSwitchSettings = makeLinkHandler('/manage/featureSwitches');

  return (
    <div className={classes.menuItem}>
      <MenuItem onClick={handleFeatureSwitchSettings}>
        <IconButton aria-label="Manage feature settings" color="inherit">
          <ViewList />
        </IconButton>
        <span>Feature switches</span>
      </MenuItem>
    </div>
  );
}

export default SuperSettingsMenu;
