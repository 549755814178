import {
  Button,
  ButtonBase,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Clear, Mic, MicOff, Warning } from '@material-ui/icons';
import { useCallback, useEffect, useState } from 'react';
import CentredLoader from '../../../components/centredLoader';
import CustomDialog from '../../../components/customDialog';
import { useDispatchAlert } from '../../../hooks';
import useVoiceToText from '../../../hooks/useVoiceToText';
import { isDevelopment } from '../../../lib/utils';
import useNaturalLanguageService from '../../../services/useNaturalLanguageService';
import { FilterParams } from '../types';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    flexGrow: 1,
    marginBottom: '16px',
  },
  search: {
    flexGrow: 1,
    marginTop: 0,
    marginBottom: 0,
    paddingRight: '5px',
  },
  centeredGrid: {
    maxWidth: '80%', // adjust as needed for desired margins
    margin: '0 auto',
  },
  accessDeniedText: { fontSize: '14px', fontFamily: 'Google Sans' },
  recordingIcon: {
    color: 'red',
    border: '2px solid red',
    borderRadius: '50%',
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

type NaturalLanguageQueryParams = {
  onApplyFilters: (filters: FilterParams) => void;
  filterOptions: FilterParams;
};

function NaturalLanguageQuery({ onApplyFilters, filterOptions }: NaturalLanguageQueryParams) {
  const classes = useStyles();

  const naturalLanguageService = useNaturalLanguageService();
  const {
    transcript,
    clearTranscript,
    isListening,
    setIsListening,
    microphonePermissionState,
    browserSettingsInstruction,
  } = useVoiceToText();

  const dispatchAlert = useDispatchAlert();
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [showMicrophoneInstructions, setShowMicrophoneInstructions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [resultParameters, setResultParameters] = useState({
    apiParameters: {},
    sortOptions: {},
  });
  const [llmDisabled, setLlmDisabled] = useState(false);
  const [micIcon, setMicIcon] = useState(<Mic />);

  const handleGetReferrals = useCallback(
    (filterParameters: { sortOptions: any; apiParameters: any }) => {
      const { sortOptions, apiParameters } = filterParameters;

      onApplyFilters({
        ...filterOptions,
        ...sortOptions,
        page: 0,
        filter: apiParameters,
      });
      setShowResults(false);
    },
    [filterOptions, onApplyFilters],
  );

  const handleSearch = useCallback(async () => {
    setIsListening(false);
    setLoading(true);

    const parameters = await naturalLanguageService
      .getCompletion(searchQuery)
      .catch(async (err) => {
        let additionalMessage = '';

        if (err.details?.status === 429) {
          setLlmDisabled(true);
          additionalMessage = 'Try again in a few seconds.';

          // Setting a timeout for 5 seconds to re-enable LLM
          setTimeout(() => {
            setLlmDisabled(false);
          }, 5000);
        }

        const alertMessage = `Failed to query parameters from LLM: ${err.message}. ${additionalMessage}`;
        dispatchAlert(alertMessage);
      })
      .finally(() => setLoading(false));

    if (parameters) {
      setResultParameters(parameters);

      if (isDevelopment) {
        setShowResults(true);
      } else {
        handleGetReferrals(parameters);
      }
    }
  }, [dispatchAlert, handleGetReferrals, searchQuery, setIsListening]);

  const onClose = () => {
    setShowResults(false);
  };

  const onCloseMicrophoneInstructions = () => {
    setShowMicrophoneInstructions(false);
  };

  const handleEnterKeyPress = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        handleSearch(); // Trigger search function on "Enter" key press
      }
    },
    [handleSearch],
  );

  useEffect(() => {
    if (searchQuery.length === 0) {
      return () => {};
    }

    window.addEventListener('keydown', handleEnterKeyPress); // Add event listener
    return () => {
      window.removeEventListener('keydown', handleEnterKeyPress); // Remove event listener on component unmount
    };
  }, [handleEnterKeyPress, searchQuery]);

  useEffect(() => {
    if (!isListening) {
      return;
    }

    if (transcript.toLowerCase().includes('go now')) {
      handleSearch();
    } else if (transcript.toLowerCase().includes('start again')) {
      clearTranscript();
      setSearchQuery('');
    } else {
      setSearchQuery(transcript);
    }
  }, [clearTranscript, handleSearch, isListening, setIsListening, transcript]);

  const renderObjectProperties = (obj: any) => (
    <ul>
      {Object.keys(obj).map((key) => {
        const value = obj[key];

        // If the value is an array
        if (Array.isArray(value)) {
          return (
            <li key={key}>
              {key}:
              <ul>
                {value.map((item, _index) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </li>
          );
        }
        // If the value is an object
        if (typeof value === 'object' && value !== null) {
          return (
            <li key={key}>
              {key}:{renderObjectProperties(value)}
            </li>
          );
        }
        // Otherwise, just render the key-value pair

        return (
          <li key={key}>
            {key}: {value}
          </li>
        );
      })}
    </ul>
  );

  const handleReset = () => {
    clearTranscript();
    setSearchQuery('');
  };

  useEffect(() => {
    if (microphonePermissionState === 'granted') {
      setMicIcon(isListening ? <Mic className={classes.recordingIcon} /> : <Mic />);
    } else {
      setMicIcon(<MicOff />);
    }
  }, [classes.recordingIcon, isListening, microphonePermissionState]);

  return (
    <>
      <Paper className={classes.paper} elevation={0}>
        <CentredLoader loading={loading} />
        <Typography align="center">
          {microphonePermissionState === 'denied' && (
            <ButtonBase
              className={classes.accessDeniedText}
              onClick={() => setShowMicrophoneInstructions(true)}
            >
              <Warning style={{ marginRight: 8 }} color="error" fontSize="small" />
              <Typography color="error">
                Microphone access is denied. Click here for instructons to adjust microphone
                settings
              </Typography>
            </ButtonBase>
          )}
        </Typography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.centeredGrid}
        >
          <Grid item xs={2}>
            <b>Search for referrals</b>
          </Grid>
          <Grid item xs={8} className={classes.searchContainer}>
            <TextField
              id="search-query"
              className={classes.search}
              variant="outlined"
              margin="dense"
              value={searchQuery}
              fullWidth
              disabled={llmDisabled}
              onChange={(e: any) => setSearchQuery(e.target.value.trim())}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchQuery && (
                      <IconButton onClick={handleReset}>
                        <Clear />
                      </IconButton>
                    )}
                    <IconButton
                      onClick={() => setIsListening((prevState) => !prevState)}
                      disabled={
                        ['denied', 'notAvailable'].includes(microphonePermissionState) ||
                        llmDisabled
                      }
                    >
                      {micIcon}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              color="primary"
              variant="contained"
              disabled={!searchQuery || llmDisabled}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {microphonePermissionState === 'notAvailable' && (
          <Typography
            color="error"
            align="center"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Warning style={{ marginRight: 8 }} color="error" fontSize="small" />
            Voice recognition is not available in your browser. If you would like to use voice
            recognition, please try a different browser or a newer version of your current browser.
          </Typography>
        )}
      </Paper>

      <CustomDialog open={showResults} onClose={onClose} title="Search Parameters">
        <DialogContent>
          <div>Query {searchQuery} returned the following results:</div>
          {resultParameters?.apiParameters &&
            renderObjectProperties(resultParameters.apiParameters)}
          {resultParameters?.sortOptions && renderObjectProperties(resultParameters.sortOptions)}
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            onClick={() => handleGetReferrals(resultParameters)}
            color="primary"
            variant="contained"
            disabled={
              !resultParameters ||
              (Object.keys(resultParameters.apiParameters).length === 0 &&
                Object.keys(resultParameters.sortOptions).length === 0)
            }
          >
            Get Referrals
          </Button>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </CustomDialog>

      <CustomDialog
        open={showMicrophoneInstructions}
        onClose={onCloseMicrophoneInstructions}
        title="Enable Microphone Access for Voice Recognition"
      >
        <DialogContent>{browserSettingsInstruction}</DialogContent>
        <DialogActions>
          <Button onClick={onCloseMicrophoneInstructions} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}

export default NaturalLanguageQuery;
