import { useCallback } from 'react';
import useInsecureAPI from '../auth/useInsecureAPI';
import { OrgSettings } from '../lib/orgSettings';

type InitData = {
  orgAId: string;
  orgName: string;
  orgSettings: OrgSettings;
};

/**
 * Allows getting auth0 org ID and theme data without token
 */
function useInitService() {
  const { get, loading } = useInsecureAPI('init');

  const getInitData = useCallback(() => get<InitData>(''), [get]);

  return {
    getInitData,
    loading,
  };
}

export default useInitService;
