import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  emailSuccess: {
    color: theme.palette.success.main,
  },
  referralIcon: {
    marginBottom: '0.25rem',
    marginLeft: '0.25rem',
  },
  warningIcon: {
    width: '30px',
    height: '30px',
  },
}));

export default useStyles;
