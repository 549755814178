import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { DatePicker, LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import moment from 'moment-timezone';
import { useEffect, useState } from 'preact/hooks';
import { ColumnId } from '../referralTableColumn';

type CreatedDateFilterProps = Readonly<{
  value: Date | null;
  cellId: ColumnId;
  onFilterChange: (cellId: ColumnId, value?: Date) => void;
  disableFuture: boolean;
}>;

function CreatedDateFilter({
  cellId,
  value,
  onFilterChange,
  disableFuture,
}: CreatedDateFilterProps) {
  // Initial value should come from filter state
  const [selectedCreatedDate, setCreatedDate] = useState<Date | null>(value);

  // Flag whether to show datepicker window (triggered when user clicks on date picker)
  const [open, setOpen] = useState(false);

  // Updated created date when user selects new date
  const handleCreatedAtChange = (createdDate: Date | null) => {
    const selectedDate = moment(createdDate).isValid()
      ? moment(createdDate).startOf('day').toDate()
      : undefined;
    setCreatedDate(selectedDate ?? null);
    onFilterChange(cellId, selectedDate);
  };

  useEffect(() => {
    if (value == null) {
      setCreatedDate(null);
    }
  }, [value]);

  return (
    <LocalizationProvider dateAdapter={DateFnsAdapter as any}>
      <DatePicker
        disableFuture={disableFuture}
        name={`${cellId}Filter`}
        inputFormat="d MMM yyyy"
        mask=""
        value={selectedCreatedDate}
        onChange={handleCreatedAtChange}
        open={open}
        onAccept={() => {
          setOpen(false);
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            fullWidth
            margin="dense"
            onClick={() => {
              setOpen((prevState) => !prevState);
            }}
            onKeyDown={(e: KeyboardEvent) => {
              // Disable keyboard input, to avoid validation etc. User must pick from date picker
              if (e.code !== 'Tab') {
                e.preventDefault();
              }
            }}
            helperText={null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!moment(selectedCreatedDate).isValid() ? (
                    ''
                  ) : (
                    <IconButton
                      aria-label="clear date"
                      onClick={(e: Event) => {
                        e.stopPropagation();
                        handleCreatedAtChange(null);
                      }}
                      style={{ padding: 0 }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default CreatedDateFilter;
