import { ClinicianStatus } from '../../lib/types/clinicianStatus';
import { ReferralAlert } from '../../lib/types/referralAlerts';
import { ReferralStatus } from '../../lib/types/referralStatus';
import { TestStatus } from '../../lib/types/testStatus';

export const workflowHoverDescriptions: Record<ReferralStatus, string> = {
  [ReferralStatus.WaitingList]: 'Patient is waiting for a questionnaire to be sent',
  [ReferralStatus.DetailsRequested]:
    'A message has been sent to the patient requesting contact information',
  [ReferralStatus.HQSent]:
    'The questionnaire has been sent to the patient, but it has not been filled',
  [ReferralStatus.HQReceived]: 'The questionnaire has been filled by the patient',
  [ReferralStatus.AppointmentBooked]:
    'Patient has appointment date to come into clinic and see a Dr',
  [ReferralStatus.ReadyForSurgery]: 'The patient is ready for surgery, but a date has not been set',
  [ReferralStatus.BookedForSurgery]: 'The patient is ready for surgery and a date has been set',
  [ReferralStatus.PostOpHQSent]: 'The post-op questionnaire has been sent to the patient',
  [ReferralStatus.PostOpHQReceived]: 'The post-op questionnaire has been filled by the patient',
  [ReferralStatus.Cancelled]: 'Surgery for the patient has been cancelled',
  [ReferralStatus.ReadyForBowelPrep]: 'The patient is ready for bowel prep',
};

export const clinicianHoverDescriptions: Record<ClinicianStatus, string> = {
  [ClinicianStatus.None]: '',
  [ClinicianStatus.Requested]: 'There is an open clinical question for review',
  [ClinicianStatus.Received]: 'The clinical question was answered',
};

export const alertHoverDescriptions: Record<ReferralAlert, string> = {
  [ReferralAlert.EmailInvalid]: 'Patient does not have an email',
  [ReferralAlert.EmailNotReceived]: 'Failed to send email to patient',
  [ReferralAlert.EmailOptOut]: 'Patient has opted out of receiving emails',
  [ReferralAlert.Medication]: 'Patient has medication warnings',
  [ReferralAlert.AdviceUnsent]:
    'Patient is ready for surgery but needs to be sent pre-surgery advice',
};

export const testHoverDescriptions: Record<TestStatus, string> = {
  [TestStatus.None]: '',
  [TestStatus.Updated]: 'Tests have been added but not ordered',
  [TestStatus.Ordered]: 'Tests have been ordered for the patient and are awaiting results/review',
  [TestStatus.Received]: 'Test results have been reviewed',
};
