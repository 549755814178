const urlHost = process.env.PREACT_APP_RISK_ENGINE_HOST;
const urlPort = process.env.PREACT_APP_RISK_ENGINE_PORT
  ? `:${process.env.PREACT_APP_RISK_ENGINE_PORT}`
  : '';

const API_CONFIG = {
  baseUrl: urlHost + urlPort,
};

export default API_CONFIG;
