import { AwsRum } from 'aws-rum-web';

const config = {
  sessionSampleRate: 1,
  guestRoleArn: process.env.PREACT_APP_RUM_ROLE_ARN,
  identityPoolId: process.env.PREACT_APP_RUM_IDENTITY_POOL,
  endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
  telemetries: ['performance', 'errors', 'http', 'interaction'],
  allowCookies: true,
  enableXRay: true,
  disableAutoPageView: true, // override automatic sending so that page views sent only without IDs
  sessionEventLimit: 0, // capture all events
};

const APPLICATION_ID = process.env.PREACT_APP_RUM_APPLICATION_ID;
const APPLICATION_VERSION = '1.0.0';
const APPLICATION_REGION = 'ap-southeast-2';

const awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);

// awsRum.recordPageView({ pageId: '/referral', pageTags: ['en', 'referralDetails'] });
// awsRum.recordPageView({ pageId: '/referral/new', pageTags: ['en', 'newReferral'] });
// awsRum.recordPageView({
//   pageId: '/referral/new/submitted',
//   pageTags: ['en', 'newReferralSubmitted'],
// });
// awsRum.recordPageView({ pageId: '/healthquestionnaire', pageTags: ['en', 'healthquestionnaire'] });

awsRum.enable();

export default awsRum;
