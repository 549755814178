import { IconButton, MenuItem } from '@material-ui/core';

type Props = {
  onClick: () => void;
  label: string;
  icon: JSX.Element;
};

function NavbarMenuItem({ onClick, label, icon }: Props) {
  return (
    <MenuItem onClick={() => onClick()}>
      <IconButton aria-label={`Manage ${label}`} color="inherit">
        {icon}
      </IconButton>
      <p>{label}</p>
    </MenuItem>
  );
}

export default NavbarMenuItem;
