import { Alert } from '../model/alertType';
import sharedConstants from '../shared-constants';
import AlertInfo from './types/alertInfo';
import { PredictionDetails } from './types/predictionDetails';
import { ReferralRisk, RiskScore } from './types/referralRisk';
import RiskLevel, { RiskLevelType } from './types/riskLevel';
import Risk, { isScreenType, RiskType } from './types/riskType';

function computeAlertPanelColour(alerts: AlertInfo[]): string {
  const infectionRiskAlert = alerts.find((alert) => alert.type === Alert.INFECTION_RISK.type);
  if (infectionRiskAlert != null) {
    return sharedConstants.trafficLight.red;
  }

  if (alerts.length > 3) {
    return sharedConstants.trafficLight.red;
  }
  if (alerts.length > 1) {
    return sharedConstants.trafficLight.orange;
  }
  return sharedConstants.trafficLight.green;
}

function computeRiskPanelColour(risks: ReferralRisk[]): string {
  if (risks.find((risk) => risk.riskLevel === RiskLevel.High)) {
    return sharedConstants.trafficLight.red;
  }
  if (risks.find((risk) => risk.riskLevel === RiskLevel.Medium)) {
    return sharedConstants.trafficLight.orange;
  }
  return sharedConstants.trafficLight.green; // If no High or Medium risks are found, default to Low risk
}

function computeMLRiskPanelColour(risks: PredictionDetails[]): string {
  const defaultColour: string = sharedConstants.trafficLight.green;

  let maxProba: number = -1;

  risks.forEach((risk) => {
    const riskProbs: number[] | undefined = risk?.explanation?.predictionProbas?.map(
      ({ predictedProba }) => predictedProba,
    );

    if (riskProbs) {
      const maxRiskProba: number = Math.max(...riskProbs);
      maxProba = Math.max(maxProba, maxRiskProba);
    }
  });

  if (maxProba >= 0.7) {
    return sharedConstants.trafficLight.red;
  }
  if (maxProba >= 0.5) {
    return sharedConstants.trafficLight.orange;
  }
  if (maxProba >= 0) {
    return sharedConstants.trafficLight.green;
  }
  return defaultColour;
}

const RISKS_ALWAYS_SHOW: RiskType[] = [Risk.ASA, Risk.METS, Risk.MDASI];

/** Remove screen risks */
export const filterScreenTypes = (risk: ReferralRisk) => !isScreenType(risk.riskType);

export const filterRisksForDisplay =
  (onlyActionable: boolean = true) =>
  (risk: ReferralRisk) => {
    // Always include risks of these types
    if (RISKS_ALWAYS_SHOW.includes(risk.riskType)) {
      return true;
    }

    // Exclude risks of these types
    if (isScreenType(risk.riskType)) {
      return false;
    }

    // Include actionable risks (i.e. level is high, or there is a suggestion)
    if (onlyActionable) {
      if (risk.riskLevel === RiskLevel.High || (risk.suggestion && risk.suggestion.trim() !== '')) {
        return true;
      }
      return false;
    }

    return true;
  };

const ENDOSCOPY_OMITTED_RISKS: RiskType[] = [Risk.Frail, Risk.MDASI, Risk.METS, Risk.DASI];

export const filterEndoscopyRisks = (risk: ReferralRisk) =>
  !ENDOSCOPY_OMITTED_RISKS.includes(risk.riskType);

export const RiskLevelFilterText: Record<RiskLevelType, string> = {
  [RiskLevel.Low]: 'Low',
  [RiskLevel.Medium]: 'Medium',
  [RiskLevel.High]: 'High',
} as const;

export const ScreenFilterText = {
  [RiskLevel.High]: 'Actionable',
} as const;

export const SuitabilityFilterText = {
  [RiskLevel.Low]: 'Suitable',
  [RiskLevel.High]: 'Unsuitable',
};

export const riskScoreDisplayString = ({ classification, units, value }: RiskScore) => {
  // Initialize result string with name
  let result = '';

  // Append value and units if they exist
  if (value != null) {
    const scoreValue =
      typeof value === 'number' && !Number.isInteger(value) ? value.toFixed(2) : value;
    result += `${scoreValue}`;
    if (units) {
      result += ` ${units}`;
    }
  }

  // Append classification if it exists
  if (classification) {
    result += value != null ? ` (${classification})` : `${classification}`;
  }

  return result;
};

/**
 * Get risk color style by level
 *
 * Usage:
 * ```
 * <div style={getRiskStyle(riskLevel)}>{riskLevel}</div>
 * ```
 */
export function getRiskStyle(riskLevel: RiskLevelType) {
  switch (riskLevel) {
    case RiskLevel.High:
      return { color: `${sharedConstants.trafficLight.red}` };
    case RiskLevel.Medium:
      return { color: `${sharedConstants.trafficLight.orange}` };
    case RiskLevel.Low:
      return { color: `${sharedConstants.trafficLight.green}` };
    default:
      return {};
  }
}

/**
 * Get color string for a risk level
 *
 * @returns Color for risk level, or transparent if risk level is invalid
 */
export function getRiskColor(riskLevel: RiskLevelType | undefined) {
  switch (riskLevel) {
    case RiskLevel.High:
      return sharedConstants.trafficLight.red;
    case RiskLevel.Medium:
      return sharedConstants.trafficLight.orange;
    case RiskLevel.Low:
      return sharedConstants.trafficLight.green;
    default:
      return 'transparent';
  }
}

export { computeAlertPanelColour, computeMLRiskPanelColour, computeRiskPanelColour };
