import { useEffect, useState } from 'react';
import { ScreeningModeType } from '../lib/screeningMode';
import useOrgSettingService from '../services/useOrgSettingService';

const useScreeningMode = () => {
  const { getAllSettings, loading: settingsLoading } = useOrgSettingService();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [screeningMode, setScreeningMode] = useState<ScreeningModeType | null>(null);

  const loading = isLoading || settingsLoading;

  useEffect(() => {
    setIsLoading(true);
    getAllSettings()
      .then((settings) => setScreeningMode(settings.screeningMode))
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [getAllSettings]);

  return { screeningMode, loading, error };
};

export default useScreeningMode;
