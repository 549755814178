import { useEffect, useState } from 'preact/hooks';
import useInitService from '../services/useInitService';
import { OrgSettings } from '../lib/orgSettings';

// custom hook to get org id for the current endpoint
function useCustomer() {
  const { getInitData, loading: initLoading } = useInitService();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [orgId, setOrgId] = useState<string | null>(null);
  const [orgSettings, setOrgsettings] = useState<OrgSettings | null>(null);

  const loading = isLoading || initLoading;

  useEffect(() => {
    setIsLoading(true);
    getInitData()
      .then(({ orgAId, orgSettings: os }) => {
        setOrgId(orgAId);
        setOrgsettings(os);
      })
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, [getInitData]);

  return { orgId, orgSettings, error, loading };
}

export default useCustomer;
