import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'preact/hooks';
import { useSearchParams } from 'react-router-dom';
import FullscreenErrorPage from '../components/fullscreenErrorPage';
import LoaderTextPage from '../components/loaderTextPage';
import RoutePath from '../components/routePaths';
import { getAuth0Error } from './apiUtils';

/* This is the login page that Auth0 will create invite links to */
function LoginPage() {
  const [searchParams] = useSearchParams();
  const invitation = searchParams.get('invitation');
  const organization = searchParams.get('organization');
  const orgName = searchParams.get('organization_name');

  const audience = process.env.PREACT_APP_AUTH0_AUDIENCE;
  const { error, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const errorCode = getAuth0Error(error);

  useEffect(() => {
    if (organization && !error) {
      // Need to do extra processing after accepting an invite, otherwise redirect to home
      if (invitation) {
        loginWithRedirect({
          authorizationParams: { invitation, organization, audience },
          appState: { returnTo: RoutePath.Invite, orgName },
        });
      } else {
        loginWithRedirect({
          authorizationParams: { organization, audience },
          appState: { returnTo: RoutePath.Home, orgName },
        });
      }
    }
  }, [audience, error, invitation, loginWithRedirect, orgName, organization]);

  if (errorCode) {
    return (
      <FullscreenErrorPage
        error={errorCode}
        showButton={false}
        extraText="Please contact your administrator"
      />
    );
  }

  if (!isAuthenticated) {
    return <LoaderTextPage content="Processing invite..." loading={isLoading} />;
  }
}

export default LoginPage;
