import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import ContactInfoNoEmailBadge from '../../../../iconComponents/contactInfoNoEmailBadge';
import useStyles from './styles';

type ContactPrefIconProps = {
  title: string;
};

function ContactInfoAlertIcon({ title }: Readonly<ContactPrefIconProps>) {
  const classes = useStyles();

  return (
    <Tooltip title={title} arrow>
      <ContactInfoNoEmailBadge
        alt="Contact info"
        className={clsx(classes.warningIcon, classes.referralIcon)}
      />
    </Tooltip>
  );
}

export default ContactInfoAlertIcon;
