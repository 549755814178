import { useCallback } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import { FilterParams, ReferralTableItem } from '../routes/referralList/types';

const filterParamMapper = (filterParams: FilterParams) => ({
  page: filterParams.page + 1,
  limit: filterParams.rowsPerPage,
  sort: { orderBy: filterParams.orderBy, order: filterParams.order },
  filter: filterParams.filter,
});

function usePatientWorkflowService() {
  const { get, loading } = useSecureAPI('patientWorkflows');

  const getPatientWorkflows = useCallback(
    (params: FilterParams, options: RequestInit): Promise<ReferralTableItem[]> =>
      get('', options, { params: JSON.stringify(filterParamMapper(params)) }),
    [get],
  );

  const getPatientWorkflowsCount = useCallback(
    (params: FilterParams, options: RequestInit): Promise<number> =>
      get<{ count: number }>('count', options, {
        params: JSON.stringify(filterParamMapper(params)),
      }).then((res) => res.count),
    [get],
  );

  return {
    getPatientWorkflows,
    getPatientWorkflowsCount,
    loading,
  };
}

export default usePatientWorkflowService;
