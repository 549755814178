import { Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ReferralStatus, referralStatusNames } from '../../../lib/types/referralStatus';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
const tZone = dayjs.tz.guess();

export type ReferralStatusCellProps = {
  state: ReferralStatus;
  lastTransitionDate: Date | null;
};

function ReferralStatusCell({ state, lastTransitionDate }: ReferralStatusCellProps) {
  const stateName = referralStatusNames[state];
  const lastDate = dayjs(lastTransitionDate).tz(tZone, true);
  const daysAfterLastTransition = dayjs().diff(lastDate, 'day');
  const lastTransitionDaysAgoStr = dayjs(lastDate).fromNow();

  return (
    <>
      {stateName}
      {(stateName === referralStatusNames.hqSent ||
        stateName === referralStatusNames.hqReceived) && (
        <Typography color={daysAfterLastTransition > 7 ? 'error' : 'initial'}>
          ({lastTransitionDaysAgoStr})
        </Typography>
      )}
    </>
  );
}

export default ReferralStatusCell;
