import { useContext } from 'react';
import { validateEmail } from '../lib/utils';
import { StoreContext } from '../store';

/**
 * Exposes bool indicating if valid email target is attached to referral (patient email or NOK email)
 *
 * Depends on context referralData
 */
function useCanEmailPatient() {
  const [state] = useContext(StoreContext);
  const { referralData } = state;
  const { kinCorrespondence, patientEmailAddress, kinEmailAddress } = referralData;

  const patientEmail = validateEmail(patientEmailAddress);
  const nokEmail = !!kinCorrespondence && validateEmail(kinEmailAddress);
  return patientEmail || nokEmail;
}

export default useCanEmailPatient;
