import { useAuth0 } from '@auth0/auth0-react';
import { useMemo, useState } from 'react';
import { AppError, ErrorName } from '../components/errorBoundary/errorHandlingUtils';
import API_CONFIG from '../services/apiConfig';
import {
  AllowedOptions,
  checkResponseForErrors,
  handleErrResponse,
  makeInsecureOptions,
  makeInsecureOptionsFormData,
  makeQueryString,
  responseToOptionalJSON,
} from './apiUtils';

function useInsecureAPI(endpoint = '') {
  const { isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const [apiLoading, setApiLoading] = useState(true);
  const baseURL = endpoint.length > 0 ? `${API_CONFIG.baseUrl}/${endpoint}` : API_CONFIG.baseUrl;

  const getOptionalToken = async () => {
    if (isAuthenticated) {
      try {
        return await getAccessTokenSilently();
      } catch (e: any) {
        if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
          await loginWithRedirect();
        }

        throw new AppError(
          {
            title: 'Missing or invalid refresh token',
            detail: e.message,
            errName: ErrorName.REQUIRES_LOGIN,
          },
          e,
        );
      }
    }
    return null;
  };

  async function postFormData<T extends {} | void = void>(path: string, data: FormData) {
    setApiLoading(true);
    const token = await getOptionalToken();
    const options = makeInsecureOptionsFormData('POST', token, data);

    return fetch(`${baseURL}/${path}`, options)
      .then(checkResponseForErrors)
      .then((r) => responseToOptionalJSON(r) as Promise<T>)
      .catch(handleErrResponse)
      .finally(() => setApiLoading(false));
  }

  async function get<T extends {}>(
    path: string,
    reqOptions: AllowedOptions | null = null,
    queryParams: Record<string, string> = {},
  ) {
    setApiLoading(true);

    const token = await getOptionalToken();
    const options = makeInsecureOptions('GET', token, undefined, reqOptions ?? undefined);
    const queryParamString = makeQueryString(queryParams);

    return fetch(`${baseURL}/${path}${queryParamString}`, options)
      .then(checkResponseForErrors)
      .then((r) => r.json() as Promise<T>)
      .catch(handleErrResponse)
      .finally(() => setApiLoading(false));
  }

  async function put<T extends {} | void = void>(path: string, data: Record<string, any>) {
    setApiLoading(true);

    const token = await getOptionalToken();
    const options = makeInsecureOptions('PUT', token, data);

    return fetch(`${baseURL}/${path}`, options)
      .then(checkResponseForErrors)
      .then((r) => responseToOptionalJSON(r) as Promise<T>)
      .catch(handleErrResponse)
      .finally(() => setApiLoading(false));
  }

  return useMemo(() => ({ put, get, postFormData, loading: apiLoading }), [endpoint]);
}

export default useInsecureAPI;
