import { useLocation } from 'react-router';
import RoutePath from '../../components/routePaths';

export type ReferralListType = 'patients' | 'endoscopy' | 'predictions' | 'none';

/**
 * @returns The type of referral list that is currently being viewed
 */
function useActiveReferralListType(): ReferralListType {
  const location = useLocation();
  const activeUrl = location.pathname;

  if (activeUrl === RoutePath.ReferralListPatients) {
    return 'patients';
  }
  if (activeUrl === RoutePath.ReferralListEndoscopy) {
    return 'endoscopy';
  }
  if (activeUrl === RoutePath.ReferralListPredictions) {
    return 'predictions';
  }
  return 'none';
}

export default useActiveReferralListType;
