import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import InfoOutlined from '@material-ui/icons/InfoOutlined';

import PatientEmailState, { PatientEmailStateType } from '../../../lib/types/patientEmailState';
import { ReferralStatus } from '../../../lib/types/referralStatus';

type EmailAlertProps = {
  emailState: PatientEmailStateType;
  referralState?: string;
  lastTransitionDate: Date | null;
  lastSmsDate?: Date;
};

const useStyles = makeStyles((theme) => ({
  emailSuccess: {
    color: theme.palette.success.main,
  },
}));

function EmailAlert({
  emailState,
  referralState,
  lastTransitionDate,
  lastSmsDate,
}: EmailAlertProps) {
  const classes = useStyles();

  return (
    <>
      {emailState === PatientEmailState.Updated && (
        <Grid container direction="row" alignItems="center">
          <InfoOutlined
            fontSize="small"
            color="secondary"
            style={{ marginBottom: '0.25rem', marginRight: '0.25rem' }}
          />
          <Typography color="secondary">Email was recently updated</Typography>
        </Grid>
      )}

      {emailState === PatientEmailState.Success && (
        <Grid container direction="row" alignItems="center">
          <EmailIcon
            fontSize="small"
            className={classes.emailSuccess}
            style={{ marginBottom: '0.25rem', marginRight: '0.25rem' }}
          />
          <Typography className={classes.emailSuccess}>Email was sent successfully</Typography>
        </Grid>
      )}

      {emailState === PatientEmailState.Failure && (
        <Grid container direction="column" alignItems="flex-start">
          <Grid container direction="row" alignItems="center">
            <ErrorOutline
              fontSize="small"
              color="error"
              style={{ marginBottom: '0.25rem', marginRight: '0.25rem' }}
            />
            <Typography color="error">Email was not received by patient</Typography>
          </Grid>
          {referralState === ReferralStatus.DetailsRequested &&
            lastSmsDate &&
            lastTransitionDate &&
            lastSmsDate >= lastTransitionDate && (
              <Typography color="error">
                Automatic SMS sent to patient to update email address; will not be resent until
                mobile number is updated.
              </Typography>
            )}
        </Grid>
      )}
    </>
  );
}

export default EmailAlert;
