import { useCallback } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import StatusType from '../lib/status/statusType';
import { FileBasicInfo } from '../lib/types/fileBasicInfo';
import { ReferralStatus } from '../lib/types/referralStatus';
import { TestStatus } from '../lib/types/testStatus';

type QuestionnaireReviewDataResponse = {
  referralData: Record<string, any>;
  hasAttachedFiles: boolean;
  attachedFiles: FileBasicInfo | null;
};

type PhotoResponse =
  | { hasPhotos: false }
  | { hasPhotos: true; photos: Record<string, string | null> };

export type CompletedHQ = {
  hqResponse: Record<string, any>;
  collectionName: string;
  submittedAt: Date | null;
  submitterFirstName: string | null;
  submitterLastName: string | null;
  questionnaireUuid: string;
};

export type IncompleteHQ = {
  collectionName: string;
  createdAt: Date | null;
};

type AdviceDetails = {
  appointmentDate: Date;
  appointmentClinic: string;
  reviewReason: string;
  notifyEmail: string;
  additionalDetails: string;
  testData: string;
  patientQuestion: string;
};

export type WorkflowStatusChange =
  | {
      statusType: StatusType.Workflow;
      newStatus: Exclude<
        ReferralStatus,
        ReferralStatus.Cancelled | ReferralStatus.BookedForSurgery
      >;
    }
  | {
      statusType: StatusType.Workflow;
      newStatus: ReferralStatus.BookedForSurgery;
      surgeryDate: string | null;
      admitDayBefore?: boolean;
    }
  | {
      statusType: StatusType.Workflow;
      newStatus: ReferralStatus.Cancelled;
      cancellationReason: string;
    };

export type TestStatusChange = { statusType: StatusType.Test; newStatus: TestStatus };

// NOTE not exhaustive list of statusType values. Add more as needed
export type StatusChange = Partial<AdviceDetails> & (WorkflowStatusChange | TestStatusChange);

function useHQReviewService() {
  const { get, post, putFormData, postFormData, downloadFileToUrl, downloadFileBlob, loading } =
    useSecureAPI('hqreview');

  const getHQResponsesByReferralUuid = useCallback(
    (referralUuid: string) => get<CompletedHQ[]>(`hqReviewSubmissions/${referralUuid}`),
    [get],
  );

  const getOutstandingQuestionnairesByReferralUuid = useCallback(
    (referralUuid: string) => get<IncompleteHQ[]>(`outstandingQuestionnaires/${referralUuid}`),
    [get],
  );

  const getQuestionnaireReviewData = useCallback(
    (referralUuid: string) =>
      get<QuestionnaireReviewDataResponse>(referralUuid).then((data) => ({
        reviewData: data.referralData.referralReviewData,
        referralData: data.referralData,
        currentState: data.referralData.currentState,
        testStatus: data.referralData.testStatus,
        drReviewStatus: data.referralData.drReviewStatus,
        patientQuestionStatus: data.referralData.questionStatus,
        hasAttachedFiles: data.hasAttachedFiles,
        attachedFiles: data.attachedFiles,
      })),
    [get],
  );

  const getPatientPhotos = useCallback(
    (referralUuid: string) => get<PhotoResponse>(`${referralUuid}/photos/photo`),
    [get],
  );

  const getPatientThumbnails = useCallback(
    (referralUuid: string, options: RequestInit) =>
      get<PhotoResponse>(`${referralUuid}/photos/thumbnail`, options),
    [get],
  );

  const getReferralAttachedFileUrl = useCallback(
    (referralUuid: string, fileUuid: string) =>
      downloadFileToUrl(`${referralUuid}/files/${fileUuid}`),
    [downloadFileToUrl],
  );

  const getReferralAttachedFileBlob = useCallback(
    (referralUuid: string, fileUuid: string) =>
      downloadFileBlob(`${referralUuid}/files/${fileUuid}`, undefined, undefined, 'GET'),
    [downloadFileBlob],
  );

  const createQuestionnaireOfCollection = useCallback(
    (referralUuid: string, collectionName: string) =>
      post<void>(`${referralUuid}/questionnaire/${collectionName}`, {}),
    [post],
  );

  const createQuestionnaire = useCallback(
    (referralUuid: string) => post<void>(`${referralUuid}/questionnaire`, {}),
    [post],
  );

  const createAllQuestionnaires = useCallback(
    (referralUuids: string[]) =>
      Promise.all(referralUuids.map((uuid) => post<void>(`${uuid}/questionnaire`, {}))),
    [post],
  );

  const sendAdvice = useCallback(
    (referralUuid: string, requestData: FormData) =>
      postFormData<void>(`${referralUuid}/sendAdvice/`, requestData),
    [postFormData],
  );

  const saveAdvice = useCallback(
    (referralUuid: string, requestData: FormData) =>
      postFormData<void>(`${referralUuid}/saveAdvice/`, requestData),
    [postFormData],
  );

  const updateStatus = useCallback(
    (referralUuid: string, requestData: StatusChange) =>
      post<void>(`${referralUuid}/status/`, requestData),
    [post],
  );

  const addProgressNotes = useCallback(
    (referralUuid: string, requestData: FormData) =>
      postFormData<{ noteId: number }>(`${referralUuid}/progressNotes/`, requestData),
    [postFormData],
  );

  const updateProgressNotes = useCallback(
    (referralUuid: string, requestData: FormData, noteId: number) =>
      putFormData<void>(`${referralUuid}/progressNotes/${noteId}`, requestData),
    [putFormData],
  );

  return {
    getHQResponsesByReferralUuid,
    getOutstandingQuestionnairesByReferralUuid,
    getQuestionnaireReviewData,
    getPatientPhotos,
    getPatientThumbnails,
    getReferralAttachedFileUrl,
    getReferralAttachedFileBlob,
    createQuestionnaireOfCollection,
    createQuestionnaire,
    createAllQuestionnaires,
    sendAdvice,
    saveAdvice,
    updateStatus,
    addProgressNotes,
    updateProgressNotes,
    loading,
  };
}

export default useHQReviewService;
