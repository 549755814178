import FullscreenErrorPage from '../components/fullscreenErrorPage';

/**
 * Rendered when user fails a claims check
 */
function AuthFailure() {
  return <FullscreenErrorPage error="Access denied" />;
}

export default AuthFailure;
