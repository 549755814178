import { SvgIcon } from '@material-ui/core';

/**
 * @param {SvgIconProps} props
 */
function CommsFailedIcon(props) {
  return (
    <SvgIcon {...props}>
      <mask
        id="mask0_2074_43"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2074_43)">
        <path
          d="M12 13L4 8V18H12C12 18.35 12.025 18.6875 12.075 19.0125C12.125 19.3375 12.2 19.6667 12.3 20H4C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H20C20.55 4 21.0208 4.19583 21.4125 4.5875C21.8042 4.97917 22 5.45 22 6V11.7C21.7 11.55 21.3792 11.425 21.0375 11.325C20.6958 11.225 20.35 11.15 20 11.1V8L12 13ZM12 11L20 6H4L12 11ZM19 23C17.6167 23 16.4375 22.5125 15.4625 21.5375C14.4875 20.5625 14 19.3833 14 18C14 16.6167 14.4875 15.4375 15.4625 14.4625C16.4375 13.4875 17.6167 13 19 13C20.3833 13 21.5625 13.4875 22.5375 14.4625C23.5125 15.4375 24 16.6167 24 18C24 19.3833 23.5125 20.5625 22.5375 21.5375C21.5625 22.5125 20.3833 23 19 23ZM16 18.5H22V17.5H16V18.5Z"
          fill="inherit"
        />
      </g>
    </SvgIcon>
  );
}

export default CommsFailedIcon;
