import { useCallback } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import Patient from '../lib/types/patient';
import PatientAllergy from '../lib/types/patientAllergy';
import PatientSurgery from '../routes/referralDetails/systemsReview/types';

function usePatientService() {
  const { post, put, get, loading } = useSecureAPI('patient');

  const getPatient = useCallback((patientUuid: string) => get<Patient>(`${patientUuid}`), [get]);

  const getPatientAllergies = useCallback(
    (patientUuid: string) => get<PatientAllergy[]>(`${patientUuid}/allergies`),
    [get],
  );

  const updatePatientAllergy = useCallback(
    (patientUuid: string, payload: PatientAllergy) => put(`${patientUuid}/allergies`, payload),
    [put],
  );

  const addPatientAllergy = useCallback(
    (patientUuid: string, payload: Omit<PatientAllergy, 'allergyId'>) =>
      post(`${patientUuid}/allergies`, payload),
    [post],
  );

  const getPatientSurgeries = useCallback(
    (patientUuid: string) => get<PatientSurgery[]>(`${patientUuid}/surgeries`),
    [get],
  );

  const updatePatientSurgery = useCallback(
    (patientUuid: string, payload: PatientSurgery) => put(`${patientUuid}/surgeries`, payload),
    [put],
  );

  const addPatientSurgery = useCallback(
    (patientUuid: string, payload: Omit<PatientSurgery, 'patientSurgeryId'>) =>
      post(`${patientUuid}/surgeries`, payload),
    [post],
  );

  const requestContactDetails = useCallback(
    (referralUuid: string) => post(`sendPatientDetailsRequest/${referralUuid}`, {}),
    [post],
  );

  const updatePatient = useCallback(
    (patientUuid: string, payload: any) => put(`${patientUuid}`, payload),
    [put],
  );

  const updateReferralPatient = useCallback(
    (gpReferralUuid: string, payload: any) =>
      put(`updateReferralPatient/${gpReferralUuid}`, payload),
    [put],
  );

  const updatePatientKin = useCallback(
    (patientUuid: string, gpReferralUuid: string, payload: any) =>
      put(`${patientUuid}/kin`, { gpReferralUuid, kinData: payload }),
    [put],
  );

  return {
    getPatient,
    getPatientAllergies,
    updatePatientAllergy,
    addPatientAllergy,
    getPatientSurgeries,
    updatePatientSurgery,
    addPatientSurgery,
    requestContactDetails,
    updatePatient,
    updateReferralPatient,
    updatePatientKin,
    loading,
  };
}

export default usePatientService;
