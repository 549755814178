/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import { IconButton, Typography, Unstable_TrapFocus } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useRef } from 'react';
import removeScrollbar from './removeScrollbar';
import useStyles from './styles';

const getMaxWidth = (limitMaxWidth: boolean | string) => {
  if (limitMaxWidth === true) {
    return '550px';
  }
  if (limitMaxWidth === false) {
    return 'none';
  }
  return limitMaxWidth;
};

const getMinWidth = (minWidth: string | number | undefined) => {
  if (minWidth === undefined) {
    return 'none';
  }
  return minWidth;
};

type CustomDialogProps = {
  children: preact.ComponentChildren;
  open: boolean;
  title?: string | JSX.Element;
  onClose: Function;
  onSubmit?: Function;
  minWidth?: string | number;
  limitMaxWidth?: string | boolean;
  safeClose?: boolean;
  closeBtn?: boolean;
};

/**
 * Copy of MUI dialog, with adjusted focus capture to avoid breaking datepickers
 * and handler for Ctrl+Enter to submit
 * This should be removed/updated if we update to MUI v5
 *
 * @param safeClose If true, don't allow clickout/ESC to close
 */
function CustomDialog({
  children,
  open,
  title = undefined,
  onClose,
  onSubmit = undefined,
  limitMaxWidth = false,
  minWidth = undefined,
  safeClose = false,
  closeBtn = true,
}: CustomDialogProps) {
  const classes = useStyles();
  const containerRef = useRef<HTMLInputElement>(null);
  const restoreRef = useRef<null | Function>(null);

  useEffect(() => {
    // If we haven't already removed scrollbar, remove it
    if (containerRef.current && !restoreRef.current) {
      restoreRef.current = removeScrollbar(containerRef.current);

      return () => {
        // Restore scrollbar on unmount
        const restoreFunc = restoreRef.current;
        if (restoreFunc) {
          restoreFunc();
          restoreRef.current = null;
        }
      };
    }
    return undefined;
  }, [open]);

  if (!open) {
    return null;
  }

  const onClickContainer = (e: any) => {
    // Don't click through to background and close dialog
    e.stopPropagation();
  };

  const onClickOutside = () => {
    if (!safeClose) {
      onClose();
    }
  };

  const rootKeyHandler = (e: KeyboardEvent) => {
    if (e.key !== 'Escape' || safeClose) {
      return;
    }
    e.stopPropagation();
    onClose();
  };

  const rootPressHandler = (e: KeyboardEvent) => {
    const { ctrlKey, key } = e;
    if (!ctrlKey || key !== 'Enter') {
      return;
    }
    if (onSubmit) {
      onSubmit();
    }
  };

  return (
    <div
      className={classes.customDialog}
      onClick={onClickOutside}
      onKeyDown={rootKeyHandler}
      onKeyPress={rootPressHandler}
      ref={containerRef}
    >
      <Unstable_TrapFocus
        disableRestoreFocus
        disableEnforceFocus
        open={open}
        isEnabled={() => open}
        getDoc={() => document}
      >
        <div
          className={classes.container}
          style={{
            maxWidth: getMaxWidth(limitMaxWidth),
            minWidth: getMinWidth(minWidth),
          }}
          onClick={onClickContainer}
        >
          <div className={classes.content}>
            {title && (
              <Typography variant="h1" className={classes.header}>
                {title}
              </Typography>
            )}
            {closeBtn && (
              <IconButton className={classes.closeButton} onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            )}
            <div className={classes.body}>{children}</div>
          </div>
        </div>
      </Unstable_TrapFocus>
    </div>
  );
}

export default CustomDialog;
