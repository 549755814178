import constants from '../shared-constants';

type UploadOptions = {
  // Comma seperated string of extensions
  allowedFileTypes?: string;
};

// Note - this could probably be moved into useSecureAPI
const downloadBlobUrl = (blobUrl: string, fileName: string) => {
  // create a link to the file and trigger download
  const link = document.createElement('a');
  link.href = blobUrl;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  if (link.parentNode) {
    window.URL.revokeObjectURL(blobUrl);
    link.parentNode.removeChild(link);
  }
};

export const downloadBuffer = (dataBuffer: Blob | MediaSource, fileName: string) => {
  const blobUrl = window.URL.createObjectURL(dataBuffer);

  downloadBlobUrl(blobUrl, fileName);
};

export const downloadFile = async (
  hqReviewService: {
    getReferralAttachedFileUrl: (referralUuid: string, fileUuid: string) => Promise<string>;
  },
  fileName: string,
  fileUuid: string,
  referralUuid: string,
) => {
  const fileContentUrl = await hqReviewService.getReferralAttachedFileUrl(referralUuid, fileUuid);
  downloadBlobUrl(fileContentUrl, fileName);
};

export const uploadFileCheck = (file: File, options: UploadOptions = {}) => {
  const allowedTypes = options.allowedFileTypes ?? constants.allowedFileTypes.join(', ');
  const allowedMsg = allowedTypes === '*' ? '' : `Allowed file types: ${allowedTypes}`;

  if (!file || typeof file?.name !== 'string') {
    return {
      message: allowedMsg,
      type: 'default',
    };
  }

  const fileExtension = file.name.split('.').slice(-1)[0];

  const fileIsAllowed =
    allowedTypes === '*' ? true : allowedTypes.split(', ').includes(fileExtension.toLowerCase());

  if (!fileIsAllowed) {
    return {
      message: `${constants.fileTypeNotAllowedMessage} Allowed file types: ${allowedTypes}`,
      messageKey: 'components.fileUpload.allowedTypes',
      type: 'error',
    };
  }
  if (file.size < constants.limitUploadFileSize) {
    return {
      message: constants.ableToUploadFilesMessage,
      type: 'success',
    };
  }
  return {
    message: constants.fileSizeOverMessage,
    messageKey: 'components.fileUpload.allowedTypes',
    type: 'error',
  };
};

export const patientPhotoCheck = (file: File) => {
  if (!file || typeof file?.name !== 'string') {
    return {
      message: `Allowed file types: ${constants.allowedPatientPhotoTypes.join(', ')}.`,
      messageKey: 'components.photoUpload.allowedTypes',
      type: 'default',
    };
  }

  const fileExtension = file.name.split('.').slice(-1)[0].toLowerCase();
  const fileIsAllowed = constants.allowedPatientPhotoTypes.some(
    (a) => a.toLowerCase() === fileExtension,
  );

  if (!fileIsAllowed) {
    return {
      message: `${
        constants.fileTypeNotAllowedMessage
      } Allowed file types: ${constants.allowedPatientPhotoTypes.join(', ')}.`,
      messageKey: 'components.photoUpload.typeError',
      type: 'error',
    };
  }
  if (file.size < constants.limitPhotoFileSize) {
    return {
      message: constants.ableToUploadFilesMessage,
      type: 'success',
    };
  }
  return {
    message: `File size is over the limit. (${constants.limitPhotoFileSize / 1024}KB)`,
    messageKey: 'components.photoUpload.sizeError',
    type: 'error',
  };
};

export const openPrintBlob = (blob: Blob) => {
  const blobUrl = window.URL.createObjectURL(blob);

  const iframe = document.createElement('iframe');
  iframe.src = blobUrl;
  iframe.style.position = 'absolute';
  iframe.style.width = '0';
  iframe.style.height = '0';
  iframe.style.border = '0';
  document.body.appendChild(iframe);

  iframe.onload = () => {
    iframe.contentWindow?.focus();
    iframe.contentWindow?.print();

    // Listen for user interaction as a proxy for print dialog closing
    const cleanup = () => {
      document.body.removeChild(iframe);
      URL.revokeObjectURL(blobUrl);

      window.removeEventListener('focus', cleanup);
    };

    // Add event listener for when the user refocuses the window (after print dialog)
    window.addEventListener('focus', cleanup);
  };
};
