import { SvgIcon } from '@material-ui/core';

/**
 * @param {SvgIconProps} props
 */
function ContactPreNotificationsOffIcon(props) {
  return (
    <SvgIcon {...props}>
      <mask
        id="mask0_2074_50"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2074_50)">
        <path
          d="M4.00039 19V17H6.00039V10C6.00039 9.45 6.07122 8.90833 6.21289 8.375C6.35456 7.84167 6.56706 7.33333 6.85039 6.85L8.35039 8.35C8.23372 8.61667 8.14622 8.8875 8.08789 9.1625C8.02956 9.4375 8.00039 9.71667 8.00039 10V17H14.2004L1.40039 4.2L2.80039 2.8L21.2004 21.2L19.8004 22.6L16.1504 19H4.00039ZM18.0004 15.15L16.0004 13.15V10C16.0004 8.9 15.6087 7.95833 14.8254 7.175C14.0421 6.39167 13.1004 6 12.0004 6C11.5671 6 11.1504 6.06667 10.7504 6.2C10.3504 6.33333 9.98372 6.53333 9.65039 6.8L8.20039 5.35C8.53372 5.08333 8.89206 4.85 9.27539 4.65C9.65872 4.45 10.0671 4.3 10.5004 4.2V3.5C10.5004 3.08333 10.6462 2.72917 10.9379 2.4375C11.2296 2.14583 11.5837 2 12.0004 2C12.4171 2 12.7712 2.14583 13.0629 2.4375C13.3546 2.72917 13.5004 3.08333 13.5004 3.5V4.2C14.8337 4.53333 15.9171 5.2375 16.7504 6.3125C17.5837 7.3875 18.0004 8.61667 18.0004 10V15.15ZM12.0004 22C11.4504 22 10.9796 21.8042 10.5879 21.4125C10.1962 21.0208 10.0004 20.55 10.0004 20H14.0004C14.0004 20.55 13.8046 21.0208 13.4129 21.4125C13.0212 21.8042 12.5504 22 12.0004 22Z"
          fill="inherit"
        />
      </g>
    </SvgIcon>
  );
}

export default ContactPreNotificationsOffIcon;
