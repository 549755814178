import { useContext, useEffect, useState } from 'react';
import { handleErrorInFunctional } from '../../../components/errorBoundary/errorHandlingUtils';
import { useFeatures } from '../../../hooks';
import useScreeningMode from '../../../hooks/useScreeningMode';
import ScreeningMode from '../../../lib/screeningMode';
import Feature from '../../../lib/types/feature';
import { SurgicalUnitNames } from '../../../lib/types/surgicalUnit';
import { StoreContext } from '../../../store';
import { ColumnId } from '../referralTableColumn';
import { getTabOptions } from '../tabSelector';
import useDispatchFilter from '../useDispatchFilter';
import ReferralList from './referralList';
import { columnsDefault, columnsFeatures } from './referralListCommon';

const patientColumns: ColumnId[] = ['surgicalUnitName'];

function PatientListPage() {
  const [{ workflowFilterOptions }] = useContext(StoreContext);
  const { dispatchReferralListFilters } = useDispatchFilter();
  const { featureList } = useFeatures();
  const { screeningMode } = useScreeningMode();

  const [errorStatus, setErrorStatus] = useState(null);
  const [patientFilter, setPatientFilter] = useState(workflowFilterOptions);

  const screeningColumn = screeningMode === ScreeningMode.ASA ? 'asaScore' : 'complexityMetsRisk';

  // Clear procedure name filter if it exists as we don't have a column for it
  useEffect(() => {
    if (!featureList.includes(Feature.ENDOSCOPY)) {
      setPatientFilter(workflowFilterOptions);
      return;
    }

    // Need to exclude Endoscopy SU. If no option explicitly set, then exclude Endoscopy
    // A null value indicates 'No specialty' option, but empty string indicates no
    // filtering applied
    const currentSU = workflowFilterOptions.filter.surgicalUnitName;
    if (currentSU === undefined || currentSU === '' || currentSU === SurgicalUnitNames.Endoscopy) {
      setPatientFilter({
        ...workflowFilterOptions,
        filter: {
          ...workflowFilterOptions.filter,
          procedureName: undefined,
          surgicalUnitName: `!${SurgicalUnitNames.Endoscopy}`,
        },
      });
    } else {
      setPatientFilter(workflowFilterOptions);
    }
  }, [featureList, workflowFilterOptions]);

  handleErrorInFunctional(errorStatus, setErrorStatus);

  return (
    <ReferralList
      showTabs
      columns={[
        ...columnsDefault,
        ...columnsFeatures(featureList),
        ...patientColumns,
        screeningColumn,
      ]}
      filterOptions={patientFilter}
      onFilterChange={dispatchReferralListFilters}
      featureList={featureList}
      tabOptions={getTabOptions('patients')}
    />
  );
}

export default PatientListPage;
