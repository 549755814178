import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@material-ui/core';

function SignOutButton() {
  const { logout } = useAuth0();

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <Button variant="contained" name="btnSignOut" color="primary" onClick={handleLogout}>
      Sign out
    </Button>
  );
}

export default SignOutButton;
