const RoutePath = {
  Home: '/',
  ReferralListPatients: '/referrals/list/patients',
  ReferralListEndoscopy: '/referrals/list/endoscopy',
  ReferralListPredictions: '/referrals/list/predictions',
  Overview: '/overview',
  BusinessAnalytics: '/business_analytics',
  Invite: '/invite',
  FeatureAccessDisabled: '/feature_access_disabled',
} as const;

export default RoutePath;
