import moment from 'moment-timezone';
import { useState } from 'preact/hooks';
import { DateRange, DateRangePicker, Range, RangeKeyDict } from 'react-date-range';

import { Dialog, IconButton, InputAdornment, TextField } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { ColumnId, DateRangeValues } from '../referralTableColumn';
import { TabOptionKey } from '../tabSelector';

type SurgeryDateFilterProps = {
  allowPast?: boolean;
  dateStartFilter?: Date;
  dateEndFilter?: Date;
  cellId: ColumnId;
  currentTab: TabOptionKey;
  onFilterChange: (cellId: ColumnId, value: DateRangeValues) => void;
};

function SurgeryDateFilter({
  allowPast = false,
  dateStartFilter,
  dateEndFilter,
  cellId,
  currentTab,
  onFilterChange,
}: SurgeryDateFilterProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dateRage, setDateRange] = useState<Range[]>([
    {
      startDate: dateStartFilter,
      endDate: dateEndFilter,
      key: 'selection',
    },
  ]);

  const formatDateRange = () => {
    if (!dateStartFilter && !dateEndFilter) {
      return '';
    }

    const startDate = dateStartFilter && moment(dateStartFilter).format('D MMM YYYY').toString();
    const endDate = dateEndFilter && moment(dateEndFilter).format('D MMM YYYY').toString();

    return `${startDate || ''} - ${endDate || ''}`;
  };

  const changeDateRange = (surgeryDate: Range) => {
    setDateRange([surgeryDate]);
    onFilterChange(cellId, surgeryDate);
  };

  const handleClearFilter = (e: Event) => {
    // prevent opening a dialog
    e.stopPropagation();
    onFilterChange(cellId, {});
  };

  const disableEdit = currentTab === TabOptionKey.PostOp;

  return (
    <>
      <TextField
        name={`${cellId}Filter`}
        fullWidth
        value={formatDateRange()}
        onClick={() => {
          if (!disableEdit) {
            setDialogOpen(true);
          }
        }}
        onKeyDown={(e: KeyboardEvent) => {
          // Disallow typing
          if (e.code !== 'Tab') {
            e.preventDefault();
          }
        }}
        margin="dense"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {dateStartFilter || dateEndFilter ? (
                <IconButton
                  aria-label="clear date range"
                  onClick={(e: Event) => handleClearFilter(e)}
                  style={{ padding: 0 }}
                  disabled={disableEdit}
                >
                  <HighlightOffIcon />
                </IconButton>
              ) : (
                ''
              )}
            </InputAdornment>
          ),
        }}
        disabled={disableEdit}
      />

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        {allowPast ? (
          <DateRangePicker
            onChange={(surgeryDate: RangeKeyDict) => {
              changeDateRange(surgeryDate.selection);
            }}
            ranges={dateRage}
            direction="horizontal"
          />
        ) : (
          <DateRange
            onChange={(surgeryDate: RangeKeyDict) => {
              changeDateRange(surgeryDate.selection);
            }}
            minDate={new Date()}
            ranges={dateRage}
            direction="horizontal"
          />
        )}
      </Dialog>
    </>
  );
}

export default SurgeryDateFilter;
