import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  statusHeading: {
    padding: '2px 8px',
    fontWeight: 800,
    borderRadius: '3px',
  },
  mainStatus: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    fontSize: '1.5rem',
  },
  subStatus: {
    backgroundColor: '#e4e4e3',
    fontSize: '0.9rem',
  },
}));

type StatusHeadingProps = {
  main?: boolean;
  className?: string | null;
  children: JSX.Element | JSX.Element[] | string;
};

function StatusHeading({ main = false, className = null, children }: StatusHeadingProps) {
  const classes = useStyles();
  const newClassName = clsx(
    classes.statusHeading,
    main && classes.mainStatus,
    !main && classes.subStatus,
    className,
  );

  return <span className={newClassName}>{children}</span>;
}

export default StatusHeading;
