import { Button, ButtonProps, CircularProgress } from '@material-ui/core';
import { JSXInternal } from 'preact/src/jsx';

type LoadingButtonProps = ButtonProps & {
  type?: 'submit';
  className?: string;
  onClick?: () => void;
  loading?: boolean;
  children: JSX.Element | string;
  style?: JSXInternal.CSSProperties;
};

/**
 * Button which which is disabled and displays a loader when loading
 *
 * This should be removed after upgrading to MUIv5
 */
function LoadingButton({
  type,
  className,
  variant = 'text',
  color = 'primary',
  onClick,
  loading = false,
  disabled = false,
  children,
  ...props
}: LoadingButtonProps) {
  const endIcon = loading ? <CircularProgress size={20} color="inherit" /> : undefined;

  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      onClick={onClick}
      disabled={loading || disabled}
      endIcon={endIcon}
      className={className}
      {...props}
    >
      {children}
    </Button>
  );
}

export default LoadingButton;
