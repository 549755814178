import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { StylesProvider, createTheme, jssPreset } from '@material-ui/core/styles';
import { useContext, useEffect, useState } from 'preact/hooks';

import { create } from 'jss';
import rtl from 'jss-rtl';
import { useLocation } from 'react-router';
import { StoreContext, localeActionTypes } from '../store';
import theme, { themeDef } from '../style/theme';

type Props = {
  children: JSX.Element | JSX.Element[];
};

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function ThemeProvider({ children }: Props) {
  const location = useLocation();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [state, dispatch] = useContext(StoreContext);
  const { localeCode } = state;

  // RTL is only supported for questionnaire
  const isQuestionnaireRoute = location.pathname?.indexOf('/healthquestionnaire') === 0;

  useEffect(() => {
    if (!isQuestionnaireRoute && localeCode !== 'en') {
      // Swap to english locale if we aren't in a questionnaire
      dispatch({ type: localeActionTypes.SET_LOCALE, payload: 'en' });
    }
  }, [dispatch, isQuestionnaireRoute, localeCode]);

  const arabicTheme = () => {
    const arabicThemeDef = {
      direction: 'rtl' as const,
      ...themeDef,
    };

    // Seems like this should be valid (and it works), but typescript doesn't like it
    // https://v4.mui.com/customization/components/#5-global-theme-variation
    arabicThemeDef.typography.body1.fontSize = '1.25rem' as any;
    arabicThemeDef.typography.body2.fontSize = '1.25rem' as any;
    (arabicThemeDef.typography.button as any).fontSize = '1.25rem';
    arabicThemeDef.typography.caption.fontSize = '1.25rem';
    (arabicThemeDef.overrides as any).MuiGrid = {
      'spacing-xs-2': {
        '& > $item': {
          padding: '12px',
        },
      },
    };
    return createTheme(arabicThemeDef);
  };

  useEffect(() => {
    if (localeCode === 'ar' && isQuestionnaireRoute) {
      document.documentElement.dir = 'rtl';
      setSelectedTheme(arabicTheme());
    } else {
      setSelectedTheme(theme);
      document.documentElement.dir = 'ltr';
    }
  }, [isQuestionnaireRoute, localeCode]);

  return (
    <MuiThemeProvider theme={selectedTheme}>
      <StylesProvider jss={jss}>
        <CssBaseline />
        {children}
      </StylesProvider>
    </MuiThemeProvider>
  );
}

export default ThemeProvider;
