import { useCallback } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import { OrgSettings } from '../lib/orgSettings';
import { ScreeningModeType } from '../lib/screeningMode';

function useOrgSettingService() {
  const { get, put, loading } = useSecureAPI('orgSettings');

  const getAllSettings = useCallback(() => get<OrgSettings>(''), [get]);
  const updateScreeningMode = useCallback(
    (mode: ScreeningModeType) => put<void>('screeningMode', { screeningMode: mode }),
    [put],
  );

  return { getAllSettings, updateScreeningMode, loading };
}

export default useOrgSettingService;
