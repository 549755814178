import { ComponentChildren, createContext } from 'preact';
import { useReducer } from 'preact/hooks';
import { useMemo } from 'react';

import initialState, { StateType } from './initialState';
import { alertReducer, localeReducer, referralReducer } from './reducer';
import { ActionData, DispatchType, ReducerType } from './types';

const reduceReducers =
  (...reducers: ReducerType[]) =>
  (prevState: any, value: ActionData) =>
    reducers.reduce((newState, reducer) => reducer(newState, value), prevState);

const mainReducer = reduceReducers(referralReducer, alertReducer, localeReducer);
const StoreContext = createContext<[StateType, DispatchType]>([initialState, (_action: any) => {}]);

function StoreProvider({ children }: { children: ComponentChildren }) {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const value = useMemo<[StateType, DispatchType]>(() => [state, dispatch], [state, dispatch]);

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
}

export default StoreProvider;
export { StoreContext, StoreProvider };
