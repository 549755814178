import { Grid, Typography, makeStyles } from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import clsx from 'clsx';
import { ComponentChildren } from 'preact';

type AlertMultilineProps = {
  title: string;
  severity: Color;
  standalone?: boolean;
  children?: ComponentChildren;
};

const useStyles = makeStyles(() => ({
  alert: { margin: '0.5rem' },
  fullWidth: { width: '100%' },
  title: { fontWeight: 800, top: 2, position: 'relative' },
}));

export default function AlertMultiline({
  title,
  severity,
  standalone = false,
  children,
}: AlertMultilineProps) {
  const fullWidth = !standalone;
  const variant = standalone ? 'filled' : 'standard';

  const classes = useStyles();

  return (
    <Grid container alignItems="center" justifyContent="center">
      <MuiAlert
        variant={variant}
        severity={severity}
        className={clsx(classes.alert, fullWidth && classes.fullWidth)}
      >
        <AlertTitle className={classes.title}>{title}</AlertTitle>
        <Typography variant="body1">{children}</Typography>
      </MuiAlert>
    </Grid>
  );
}
