// eslint-disable-next-line import/prefer-default-export
export const globalStyles = (theme) => ({
  paper: {
    fontSize: '1rem',
    backgroundColor: '#FFFFFF',
    marginTop: '0px !important',
    borderRadius: '0px 0px 4px 4px',
    zIndex: 1,
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  root: {
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(1),
    },
  },
  tableColCheckbox: {
    width: theme.spacing(1),
  },
  spinner: {
    left: '50%',
    marginTop: theme.spacing(10),
  },
  customTable: {
    fontSize: 16,

    "& th[class*='MuiTableCell-root']": {
      backgroundColor: '#ffffff !important',
      height: '3rem',
      border: 'none !important',
    },
    "& td[class*='MuiTableCell-root']": {
      border: 'none !important',
    },
    '& .MuiTableCell-body': {
      fontWeight: 'normal !important',
    },
    "& span[class*='MuiTableSortLabel-root']": {
      color: '#323232 !important',
      fontWeight: 700,
      fontSize: 16,
    },
    "& .MuiTableCell-head:first-child span[class*='MuiTableSortLabel-root']": {
      paddingLeft: 19,
    },
    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: '#f0f0f0',
    },
    "& span[class*='MuiTableSortLabel-root']:hover": {
      color: '#2196f3 !important',
      '& .MuiSvgIcon-root': {
        opacity: 1,
      },
      "& svg[class*='MuiTableSortLabel-icon']": {
        color: '#2196f3 !important',
        opacity: 0.5,
      },
    },
    "& span[class*='MuiTableSortLabel-active']": {
      color: '#2196f3 !important',
      "& svg[class*='MuiTableSortLabel-icon']": {
        color: '#2196f3 !important',
        opacity: 1,
      },
    },
    "& svg[class*='MuiTableSortLabel-icon']": {
      color: '#323232 !important',
      opacity: 0.31,
    },
    "& div[class*='MuiInputBase-root']": {
      height: '2.5rem',
    },
  },
  questionnaireText: {
    color: 'rgba(0, 0, 0, 0.87)',
    '& li': {
      marginBottom: theme.spacing(1),
    },
  },
});
