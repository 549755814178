import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { POPUser, ROLE_NAMESPACE } from '../auth/apiUtils';
import RoleName from '../lib/types/roleName';

/**
 * Get user roles.
 *
 * Should be used for conditional rendering
 */
function useUserRole() {
  const { user } = useAuth0<POPUser>();

  const res = useMemo(() => {
    const roles = user?.[ROLE_NAMESPACE] ?? [];
    return {
      isUserAdmin: roles.includes(RoleName.Admin),
      isUserNurse: roles.includes(RoleName.Nurse),
      userRoles: roles,
    };
  }, [user]);

  return res;
}

export default useUserRole;
