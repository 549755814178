import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 2,
    padding: '18px 32px',
    [theme.breakpoints.down('sm')]: {
      padding: '18px 0px',
    },
  },
  headerImg: {
    float: 'left',
    margin: '11px 0px',
    maxHeight: '30px',
    maxWidth: '100%',
    marginRight: theme.spacing(2),
  },
  brandingLines: {
    height: '82px',
    margin: '-12px 42px',
    position: 'relative',
    top: '-8px',
    width: '12px',
    [theme.breakpoints.down('sm')]: {
      margin: '-12px 24px',
    },
  },
  divider: {
    width: '1px!important',
    height: '27px',
    backgroundColor: '#fff',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  headerTitle: {
    marginLeft: theme.spacing(1),
    fontSize: 16,
    color: '#FFB27F',
    paddingTop: 3,
  },
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  navbarBtn: {
    marginRight: 20,
    fontWeight: 'bold',
    minHeight: '44px',
  },
  importBtn: {
    color: theme.palette.secondary.main,
  },
  referralBtn: {
    color: '#fff',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
  referralArrowBtn: {
    padding: 4,
  },
  menuItem: {
    '& li': {
      paddingLeft: 6,
      paddingRight: 30,
    },
  },
}));

export default useStyles;
