import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  customDialog: {
    position: 'fixed',
    top: '0',
    left: '0',
    background: '#00000073',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
    overflowY: 'auto',
  },
  container: {
    margin: 6,
    background: 'white',
    borderRadius: '0.5rem',
    boxShadow: '0px 0px 7px 3px #00000033',
    '&:focus': {
      outline: 'none',
    },
    maxHeight: 'calc(100% - 12px)',
    overflowY: 'auto',
  },
  header: {
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '1.5rem 1rem 1rem',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    textAlign: 'left',
  },
  content: {
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    padding: '0 !important',
  },
  body: {
    padding: '1rem',
  },
}));

export default useStyles;
