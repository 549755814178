enum Feature {
  ML_RISK_PREDICTION = 'MLRiskPrediction',
  ML_SCHEDULING = 'MLScheduling',
  LLM_COMPLETION_REFERRAL_LIST = 'LlmReferralListFilter',
  ARABIC_TRANSLATION = 'HQTranslationArabic',
  DASI = 'DASI', // Use DASI if feature is on, otherwise M-DASI
  REFERRAL_RISK_COLUMN = 'RiskColumn',
  REHABILITATION = 'Rehabilitation',
  PREHABILITATION = 'Prehabilitation',
  POST_OP_HQ = 'PostOpHQ',
  WAITLIST_CHECK_IN = 'WaitlistCheckIn',
  VIC_DAY_CASE_LETTER = 'VicDayCaseLetter',
  COPILOT = 'Copilot',
  QUESTIONNAIRE_AGENT = 'QuestionnaireAgent',
  ENDOSCOPY = 'Endoscopy',
}

export default Feature;
