import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import FullscreenErrorPage from '../components/fullscreenErrorPage';
import LoaderTextPage from '../components/loaderTextPage';
import useUserService from '../services/useUsers';
import { getAuth0Error, POPUser } from './apiUtils';

function InvitePage() {
  const { error, user } = useAuth0<POPUser>();
  const { acceptInvite } = useUserService();
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);
  const errorCode = getAuth0Error(error);

  useEffect(() => {
    const updateUserNameDetails = async (userId: string) => {
      const inviteResult = await acceptInvite(userId);
      setUserName(inviteResult.name);
      setLoading(false);
    };

    if (error) {
      return;
    }

    if (user) {
      const firstName = user.given_name;
      const lastName = user.family_name;
      if (!firstName && !lastName && user.sub) {
        updateUserNameDetails(user.sub);
        return;
      }

      setUserName(`${firstName} ${lastName}`);
      setLoading(false);
    }
  }, [acceptInvite, error, user]);

  if (errorCode) {
    return (
      <FullscreenErrorPage
        error={errorCode}
        showButton={false}
        extraText="Please contact your administrator"
      />
    );
  }

  return (
    <LoaderTextPage
      content={
        user && !loading ? (
          <>
            <h1 style={{ textAlign: 'center' }}>Invite Accepted</h1>
            <p style={{ textAlign: 'center' }}>Welcome, {userName}!</p>
          </>
        ) : (
          <h1>{loading ? '' : 'Unable to accept invite.'}</h1>
        )
      }
      loading={loading}
    />
  );
}

export default InvitePage;
