import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { handleErrorInFunctional } from '../../../components/errorBoundary/errorHandlingUtils';
import { useFeatures } from '../../../hooks';
import useCheckFeatures from '../../../hooks/useCheckFeatures';
import Feature from '../../../lib/types/feature';
import { SurgicalUnitNames } from '../../../lib/types/surgicalUnit';
import { StoreContext } from '../../../store';
import { ColumnId } from '../referralTableColumn';
import { getTabOptions } from '../tabSelector';
import useDispatchFilter from '../useDispatchFilter';
import ReferralList from './referralList';
import { columnsDefault, columnsFeatures } from './referralListCommon';

const endoscopyColumns: ColumnId[] = [
  'procedureName',
  'dayCase',
  'privateSuitable',
  'endoscopySuitable',
];

function EndoscopyListPage() {
  const navigate = useNavigate();
  const [{ workflowFilterOptions }] = useContext(StoreContext);
  const { dispatchReferralListFilters } = useDispatchFilter();
  const { featureList } = useFeatures();
  const [errorStatus, setErrorStatus] = useState(null);
  const [endoscopyFilter, setEndoscopyFilter] = useState(workflowFilterOptions);
  const checkEndoscopyFeature = useCheckFeatures(Feature.ENDOSCOPY);

  useEffect(() => {
    checkEndoscopyFeature().then((enabled) => {
      if (!enabled) {
        navigate('/');
      }
    });
  }, []);

  // Apply Endoscopy unit filter automatically
  useEffect(() => {
    setEndoscopyFilter({
      ...workflowFilterOptions,
      filter: {
        ...workflowFilterOptions.filter,
        surgicalUnitName: SurgicalUnitNames.Endoscopy,
      },
    });
  }, [workflowFilterOptions]);

  handleErrorInFunctional(errorStatus, setErrorStatus);

  return (
    <ReferralList
      showTabs
      columns={[...columnsDefault, ...columnsFeatures(featureList), ...endoscopyColumns]}
      filterOptions={endoscopyFilter}
      onFilterChange={dispatchReferralListFilters}
      featureList={featureList}
      tabOptions={getTabOptions('endoscopy')}
    />
  );
}

export default EndoscopyListPage;
