export enum ReferralStatus {
  WaitingList = 'waitingList',
  DetailsRequested = 'detailsRequested',
  HQSent = 'hqSent',
  HQReceived = 'hqReceived',
  AppointmentBooked = 'appointmentBooked',
  ReadyForSurgery = 'readyForSurgery',
  ReadyForBowelPrep = 'readyForBowelPrep',
  BookedForSurgery = 'bookedForSurgery',
  PostOpHQSent = 'postOpHqSent',
  PostOpHQReceived = 'postOpHqReceived',
  Cancelled = 'cancelled',
}

export const referralStatusNames: Record<ReferralStatus, string> = {
  waitingList: 'Waiting List',
  detailsRequested: 'Details Requested',
  hqSent: 'HQ Sent',
  hqReceived: 'HQ Received',
  appointmentBooked: 'Appointment Booked',
  readyForSurgery: 'Ready (no date)',
  bookedForSurgery: 'Ready (with date)',
  readyForBowelPrep: 'Ready For Bowel Prep',
  postOpHqSent: 'Post-op HQ Sent',
  postOpHqReceived: 'Post-op HQ Received',
  cancelled: 'Cancelled',
} as const;
