import { Box, Tooltip, makeStyles } from '@material-ui/core';
import { LocationOn } from '@material-ui/icons';
import clsx from 'clsx';
import useStyles from './styles';

type Props = { surgeryLocationName: string | null };

const useLocalStyles = makeStyles(() => ({
  locationIconInverted: {
    color: '#FFFFFF',
    background: '#FF0000',
    borderRadius: '50%',
    width: 26,
    height: 26,
    padding: 2,
    boxShadow: '0 0 0 2px #FFFFFF',
  },
}));

function LocationWarningIcon({ surgeryLocationName }: Props) {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  return (
    <Tooltip
      title={
        <div>
          <b>Booked into unsuitable location ({surgeryLocationName})</b>
        </div>
      }
      arrow
    >
      <Box>
        <LocationOn
          className={clsx(classes.referralIcon, localClasses.locationIconInverted)}
          fontSize="small"
        />
      </Box>
    </Tooltip>
  );
}

export default LocationWarningIcon;
