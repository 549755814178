import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { SurgicalUnit } from '../../../lib/types/surgicalUnit';
import { ColumnId } from '../referralTableColumn';
import { naturalOrder } from '../../../lib/utils';

type SpecialtyFilterProps = Readonly<{
  cellId: ColumnId;
  value: string | undefined;
  onFilterChange: (cellId: ColumnId, value: string | null) => void;
  surgicalUnits: SurgicalUnit[];
}>;

const NO_SPECIALTY = 'No specialty';

function SpecialtyFilter({ cellId, value, onFilterChange, surgicalUnits }: SpecialtyFilterProps) {
  return (
    <Autocomplete
      name={`${cellId}Filter`}
      options={surgicalUnits
        .map((su) => su.surgicalUnitName)
        .sort(naturalOrder)
        .concat([NO_SPECIALTY])}
      onChange={(_, suName) => {
        if (suName === NO_SPECIALTY) {
          onFilterChange(cellId, null);
          return;
        }
        onFilterChange(cellId, suName ?? '');
      }}
      value={value?.startsWith('!') ? undefined : value}
      renderInput={(params) => <TextField {...params} margin="dense" />}
    />
  );
}

export default SpecialtyFilter;
