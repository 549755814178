import { createTheme } from '@material-ui/core/styles';

export const themeDef = {
  props: {
    MuiTextField: {
      variant: 'outlined' as const,
    },
    MuiPaper: {
      elevation: 0,
    },
  },
  overrides: {
    MuiAutocomplete: {
      groupLabel: {
        color: '#2196f3',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '0.9rem',
        padding: '0.7rem !important',
      },
    },
    MuiFab: {
      root: {
        background: '#2196f3 !important',
        padding: '5px 17px 7px 26px !important',
      },
      label: {
        color: '#ffffff',
        fontWeight: 100,
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          color: '#FFB27F',
        },
      },
    },
    MuiAvatarGroup: {
      avatar: {
        border: 'none',
      },
    },
    MuiAvatar: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none' as const,
      },
      outlined: {
        border: 'solid 2px !important',
      },
      text: {
        padding: '9px 8px',
      },
      label: {
        padding: '2px 3px',
      },
    },
    MuiTabPanel: {
      root: {
        padding: '18px 12px',
      },
    },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: 16,
        marginLeft: 10,
        boxShadow: '0 0 51px 0 rgba(0, 0, 0, 0.5)',
      },
    },
    // Increase tooltip font size
    MuiTooltip: {
      tooltip: {
        fontSize: '1.05rem',
      },
    },
    MuiTab: {
      root: {
        position: 'relative' as const,
        display: 'block' as const,
        borderRadius: 21.5,
        textAlign: 'center' as const,
        transition: 'all .5s',
        padding: '1px',
        margin: '0px 10px',
        color: '#323232',
        height: 'auto',
        opacity: '1',
        float: 'none' as const,
        '&$selected': {
          '&, &:hover': {
            color: '#323232',
            backgroundColor: '#f2f2f2',
          },
        },
      },
      selected: {},
      wrapper: {
        lineHeight: '24px',
        textTransform: 'none' as const,
        fontSize: '16px',
        fontWeight: 'bold' as const,
        position: 'relative' as const,
        display: 'block' as const,
        color: '#323232',
      },
    },
    MuiToggleButton: {
      root: {
        color: '#929292',
        margin: '2px 16px',
        borderRadius: '21px !important',
        '&.Mui-selected': {
          backgroundColor: 'rgba(33, 150, 243, 0.16)',
          color: '#2196f3',
          '&:hover': {
            backgroundColor: 'rgba(33, 150, 243, 0.23) !important',
          },
        },
      },
      label: {
        padding: '0px 8px',
      },
    },
    MuiAccordionDetails: {
      root: {
        borderTop: '1px solid #dbdbdb',
      },
    },
    MuiAccordionSummary: {
      root: {
        '&.Mui-disabled': {
          opacity: 1,
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        color: '#323232 !important',
        fontWeight: 700,
        fontSize: 16,

        '&:hover': {
          color: '#2196f3 !important',

          '&& $icon': {
            color: '#2196f3 !important',
            opacity: 0.5,
          },
        },

        '&$active': {
          color: '#2196f3 !important',
          '&& $icon': {
            color: '#2196f3 !important',
            opacity: 1,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#091550',
    },
    secondary: {
      main: '#2196f3',
    },
    error: {
      main: '#ff5173',
    },
    success: {
      main: '#19867b',
    },
    background: {
      default: '#f2f2f2',
      lighter: '#f7f7f7',
      header: '#ececec',
    },
  },
  typography: {
    h1: {
      color: '#323232',
      fontWeight: 'bold' as const,
      fontSize: '40px',
    },
    h2: {
      color: '#e95d98',
      fontWeight: 400,
      fontSize: '2.5rem',
      marginBottom: '1rem',
      letterSpacing: '0em',
    },
    h3: {
      fontSize: 22,
      color: '#323232',
      fontWeight: 'bold' as const,
      lineHeight: 3.5,
    },
    h4: {
      marginTop: '0rem',
      fontSize: '1rem',
      color: '#e95d98',
      fontWeight: 200,
    },
    h5: {
      fontSize: '0.9rem',
      color: '#19857b',
      fontWeight: 200,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.29,
    },
    subtitle1: {
      fontSize: 16,
      color: '#323232',
      fontWeight: 'bold' as const,
    },
    subtitle2: {
      color: '#565656',
      fontSize: 14,
      fontWeight: 'bold' as const,
      lineHeight: 1.29,
    },
    caption: {
      fontSize: '0.75rem',
    },
    button: {
      textTransform: 'none' as const,
    },
    fontFamily: ['Google Sans', 'Sans-Serif'].join(','),
  },
  components: {
    MuiAlert: {
      variants: [
        {
          props: { variant: 'filled', severity: 'info' },
          style: {
            color: '#fff',
            fontWeight: 500,
            backgroundColor: '#2196f3',
          },
        },
      ],
    },
  },
};

const theme = createTheme(themeDef);

export default theme;

/**
 * Theme with square tabs, used in Referral issues tab
 */
export const squareTabTheme = createTheme({
  ...theme,
  overrides: {
    MuiTabs: {
      indicator: {
        display: 'none' as const,
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          color: '#FFB27F',
        },
      },
    },
    MuiTab: {
      root: {
        position: 'relative' as const,
        display: 'block' as const,
        borderTopLeftRadius: 6,
        borderTopBottomRadius: 6,
        textAlign: 'center' as const,
        transition: 'all .5s',
        padding: '1px',
        color: '#323232',
        height: 'auto' as const,
        opacity: '1',
        margin: '10px 0',
        float: 'none' as const,
        '&$selected': {
          '&, &:hover': {
            color: '#323232',
            backgroundColor: '#f7f7f7',
          },
        },
      },
      selected: {},
      wrapper: {
        lineHeight: '24px',
        textTransform: 'none' as const,
        fontSize: '16px',
        fontWeight: 'bold' as const,
        position: 'relative' as const,
        display: 'block' as const,
        color: '#323232',
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
  },
});

export const whiteMuiTable = {
  customTable: {
    fontSize: 16,

    "& th[class*='MuiTableCell-root']": {
      backgroundColor: '#ffffff !important',
      height: '3rem',
      border: 'none !important',
    },
    "& td[class*='MuiTableCell-root']": {
      border: 'none !important',
    },
    '& .MuiTableCell-body': {
      fontWeight: 'normal !important',
    },
    "& span[class*='MuiTableSortLabel-root']": {
      color: '#323232 !important',
      fontWeight: 700,
      fontSize: 16,
    },
    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: '#f0f0f0',
    },
    "& span[class*='MuiTableSortLabel-root']:hover": {
      color: '#2196f3 !important',
      '& .MuiSvgIcon-root': {
        opacity: 1,
      },
      "& svg[class*='MuiTableSortLabel-icon']": {
        color: '#2196f3 !important',
        opacity: 0.5,
      },
    },
    "& span[class*='MuiTableSortLabel-active']": {
      color: '#2196f3 !important',
      "& svg[class*='MuiTableSortLabel-icon']": {
        color: '#2196f3 !important',
        opacity: 1,
      },
    },
    "& svg[class*='MuiTableSortLabel-icon']": {
      color: '#323232 !important',
      opacity: 0.31,
    },
    "& div[class*='MuiInputBase-root']": {
      height: '2.5rem',
    },
  },
};
