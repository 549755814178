import { useCallback, useContext } from 'react';
import { alertActionTypes, StoreContext } from '../store';

function useDispatchAlert() {
  const [, dispatch] = useContext(StoreContext);

  const dispatchAlert = useCallback(
    (message: string, error = true) => {
      dispatch({
        type: alertActionTypes.SET_ALERT,
        payload: { message, error },
      });
    },
    [dispatch],
  );

  return dispatchAlert;
}

export default useDispatchAlert;
