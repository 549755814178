import { useCallback } from 'react';
import useSecureAPI from '../auth/useSecureAPI';
import { PartialRecord } from '../lib/tsUtils';
import { RiskType } from '../lib/types/riskType';

function usePrehabRecommendationService() {
  const { get, loading } = useSecureAPI('prehabRecommendation');

  const getPrehabRecommendations = useCallback(
    () => get<PartialRecord<RiskType, string[]>>(''),
    [get],
  );

  return {
    getPrehabRecommendations,
    loading,
  };
}

export default usePrehabRecommendationService;
