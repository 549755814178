import { Typography } from '@material-ui/core';

export type SurgicalUnitCellProps = Readonly<{
  surgicalUnitName: string | null;
  procedureName: string | null;
}>;

function SurgicalUnitCell({ surgicalUnitName, procedureName }: SurgicalUnitCellProps) {
  return (
    <>
      <Typography color="secondary" variant="h4">
        {surgicalUnitName ?? 'No specialty'}
      </Typography>
      {procedureName}
    </>
  );
}

export default SurgicalUnitCell;
